import React from 'react';
import ReactDOM from 'react-dom';
import {Amplify, Auth} from 'aws-amplify';
import * as Sentry from '@sentry/react';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {StoreProvider} from './useStore';
import config from './aws-config.json';

if (config.environment) {
    Sentry.init({
        release: process.env.REACT_APP_GIT_COMMIT,
        environment: config.environment,
        dsn: 'https://c6b6645ea621401d970d3c50773beaa2@o338168.ingest.sentry.io/5710251',
    });
}

Amplify.configure({
    Auth: {
        userPoolId: config.userPoolId,
        userPoolWebClientId: config.userPoolWebClientId,
        region: 'us-west-2',
        mandatorySignIn: true,
        oauth: {
            domain: config.oauthDomain,
            scope: ['openid'],
            redirectSignIn: window.location.origin,
            redirectSignOut: `${window.location.origin}/logout`,
            responseType: 'code'
        }
    },
    API: {
        endpoints: [{
            name: 'noissue',
            endpoint: config.apiEndpoint,
            custom_header: async () => {
                return { Authorization: `Bearer ${((await Auth.currentSession()).getIdToken().getJwtToken())}`}
            }
        }]
    }
});

ReactDOM.render(
  <React.StrictMode>
      <StoreProvider>
        <App />
      </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
