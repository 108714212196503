import {
  Button,
  Heading,
  Link,
  majorScale,
  Pane,
  Spinner,
  Strong,
  Text
} from 'evergreen-ui';
import {useParams} from 'react-router';
import {useStore} from './useStore';
import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import FeedbackDialog from './FeedbackDialog';

const PurchaseOrder = observer(() => {
  const {orderStore} = useStore();
  const {reference} = useParams();
  const [showSendFeedback, setShowSendFeedback] = useState(false);

  useEffect(() => {
    const loadOrder = async () => {
      if (orderStore.orders.length === 0) {
        await orderStore.fetchOrders();
      }
      orderStore.setCurrentOrderRef(reference);
    }
    loadOrder();
  }, [orderStore, reference]);

  function onSendFeedbackClick() {
    setShowSendFeedback(true);
  }

  return (
    orderStore.currentOrder ?
    <Pane display="flex" flexWrap="wrap" flexBasis="100%">
      <Heading size={800} flexShrink={0} flexGrow={0} flexBasis="100%" marginBottom={majorScale(1)}>{orderStore.currentOrder.reference}</Heading>
      {orderStore.isCurrentOrderLoading ? <Spinner/> :
        <Pane elevation={1} display="flex" flexBasis="100%" flexDirection="column" padding={majorScale(2)}>
          <Pane display="flex" flexBasis="100%">
            <Strong flexShrink={0} flexBasis={100}>Status</Strong>
            <Text flexShrink={0} flexGrow={0}>{orderStore.currentOrder.purchaseOrderStatus}</Text>
          </Pane>
          <Pane display="flex" flexBasis="100%">
            <Strong flexShrink={0} flexBasis={100}>SKU</Strong>
            <Text flexShrink={0} flexGrow={0}>{orderStore.currentOrder.sku}</Text>
          </Pane>
          <Pane display="flex" flexBasis="100%">
            <Strong flexShrink={0} flexBasis={100}>Name</Strong>
            <Text flexShrink={0} flexGrow={0} flexBasis="100%">{orderStore.currentOrder.productName}</Text>
          </Pane>
          <Pane display="flex" flexBasis="100%">
            <Strong flexShrink={0} flexBasis={100}>Quantity</Strong>
            <Text flexShrink={0} flexGrow={0} flexBasis="100%">{orderStore.currentOrder.quantity}</Text>
          </Pane>
          <Pane display="flex" flexBasis="100%">
            <Strong flexShrink={0} flexBasis={100}>Files</Strong>
            {orderStore.currentOrder.filesUrls.filter(f => f.googleDriveFileName.endsWith('production_pdf_file.pdf')).map(f => (
              <Link key={f.googleDriveFileId} flexShrink={0} flexGrow={0} flexBasis="100%" href={f.googleDriveViewLink} target="_blank">{f.googleDriveFileName}</Link>
            ))}
          </Pane>
        </Pane>
      }
      {!orderStore.isCurrentOrderLoading &&
        <Button disabled={orderStore.isCurrentOrderLoading} marginTop={majorScale(2)} onClick={onSendFeedbackClick}>Send feedback</Button>
      }
      <FeedbackDialog orderReference={reference} isShown={showSendFeedback} onCloseComplete={() => setShowSendFeedback(false)} />
    </Pane>
      : <Spinner marginX="auto" />
  );
});

export default PurchaseOrder;