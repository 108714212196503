import {
  majorScale,
  Pane,
  Strong,
  Tab,
  Tablist,
  Text,
  toaster
} from 'evergreen-ui';

import {
  useEffect,
  useState
} from 'react';

import _ from 'lodash';
import Papa from 'papaparse';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.min.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.min.css';
import './CsvPreviewPane.css';
import theme from "./theme";

const purchaseOrderHeaders = [
  'line',
  'reference',
  'salesOrderReference',
  'status'
];

const saleOrderHeaders = [
  'line',
  'salesOrderReference',
  'status',
  'carrier',
  'trackingNumber',
  'trackingUrl',
];

const purchaseOrderStages = [
  'Awaiting Design',
  'Sent To Print',
  'Print Underway',
  'Print Complete'
];

const salesOrderStages = [
  'Awaiting Print Completion',
  'Ready To Ship',
  'Order Shipped',
  'Delivered'
];

const purchaseOrderRequiredFields = [
  'reference',
  'status'
];

const saleOrderRequiredFields = [
  'salesOrderReference',
  'status'
];

const numberWithExponentialRegex = new RegExp('^[-+]?[0-9]*\\.?[0-9]+([eE][-+]?[0-9]+)$');
const trackingNumberRegex = new RegExp('^[a-z\\d\\s-]+$', 'i');

const checkRequiredFields = (row, requiredFields) => {
  //check missing values
  return requiredFields.map(field => {
    const value = _.get(row, field, null);
    if (!value || value === '') {
      return {field: field, message: `${field} is required`};
    }
    return null;
  });
}

const compareStringIgnoreCase = input => s => s.localeCompare(input, undefined, {sensitivity: 'base'}) === 0;

const validatePOData = (row) => {
  const validationErrors = row.validationResults || [];
  validationErrors.push(...checkRequiredFields(row, purchaseOrderRequiredFields));

  //check status field
  const userStatus = _.get(row, 'status', null);
  const status = _.find(purchaseOrderStages, compareStringIgnoreCase(userStatus));
  if (!status) {
    validationErrors.push({field: 'status', message: 'This status is unsupported'});
  }

  const validationResults = _.compact(validationErrors);

  // attach to row data for using to render style for cell & row
  row.validationResults = validationResults;

  // return null when there is no error
  if (validationResults.length === 0) {
    return null;
  }
  return row;
};

const validateSOData = (row) => {
  const validationErrors = row.validationResults || [];
  validationErrors.push(...checkRequiredFields(row, saleOrderRequiredFields));

  //check status field, user status could be in lower case
  const userStatus = _.get(row, 'status', null);
  const status = _.find(salesOrderStages, compareStringIgnoreCase(userStatus));
  console.log('status', status);
  if (!status) {
    validationErrors.push({field: 'status', message: 'This status is unsupported'});
  }

  //check trackingNumber field
  const trackingNumber = _.get(row, 'trackingNumber', null);
  if (status === 'Order Shipped' && !trackingNumber) {
    validationErrors.push({field: 'trackingNumber', message: 'TrackingNumber is missing when status is Order Shipped'});
  }
  if (!!trackingNumber &&
    (numberWithExponentialRegex.test(trackingNumber) || !trackingNumberRegex.test(trackingNumber))) {
    validationErrors.push({field: 'trackingNumber', message: 'TrackingNumber format is wrong'});
  }

  const validationResults = _.compact(validationErrors);

  // attach to row data for using to render style for cell & row
  row.validationResults = validationResults;

  // return null when there is no error
  if (validationResults.length === 0) {
    return null;
  }
  return row;
};

const getRowClass = (params) => {
  const validationResults = _.get(params, 'data.validationResults', []);
  if (!_.isEmpty(validationResults)) {
    if (_.some(validationResults, ['field', 'line'])) {
      return 'ag-row-validation-error-all';
    }
    return 'ag-row-validation-error';
  }
  if (params.node.rowIndex % 2 === 0) {
    return 'ag-row-even';
  } else {
    return 'ag-row-odd';
  }
}

const getCellClass = (params) => {
  const validationResults = _.get(params, 'data.validationResults', []);
  const isFieldError = _.findIndex(validationResults, ['field', params.colDef.field]) >= 0;
  if (isFieldError) {
    return 'ag-col-validation-error';
  }
  return '';
}

const getTooltipValue = (params) => {
  const validationResults = _.get(params, 'data.validationResults', []);
  let errorMessage = _.chain(validationResults)
    .find(['field', 'line'])
    .get('message')
    .value();
  if (_.isNil(errorMessage)) {
    errorMessage = _.chain(validationResults)
      .find(['field', params.colDef.field])
      .get('message')
      .value();
  }
  return errorMessage;
}

const CsvPreviewPane = ({file, type, setValidForUploadFn}) => {
  const [gridApi, setGridApi] = useState(null);
  const [csvRecords, setCsvRecords] = useState([]);
  const [errorRecords, setErrorRecords] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All');

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onChangeFilter = (tab) => {
    setActiveFilter(tab);
  };

  useEffect(() => {
    if (!gridApi) {
      return;
    }

    if (activeFilter === 'All') {
      gridApi.setRowData(csvRecords);
    } else {
      gridApi.setRowData(errorRecords);
    }
    //enable upload button when there is no error
    setValidForUploadFn(!!file && errorRecords.length === 0);
  }, [csvRecords, errorRecords, file, activeFilter, gridApi, setValidForUploadFn]);

  useEffect(() => {
    if (!file || !gridApi) {
      return;
    }
    const colDefs = [];
    const buildTableHeaders = (csvFields, type) => {
      // reorder, important fields need to be displayed first
      const headers = type === 'PO' ?
        _.concat(purchaseOrderHeaders, _.difference(csvFields, purchaseOrderHeaders)) :
        _.concat(saleOrderHeaders, _.difference(csvFields, saleOrderHeaders));
      const columnDefs = headers.map((header, i) => ({
        field: header,
        resizable: true,
        width: i < 1 ? 60 : 170,
        pinned: i < 2 ? 'left' : '',
        cellClass: getCellClass,
        tooltipValueGetter: getTooltipValue
      }));

      const hasNoChange = _.isEqual(colDefs, columnDefs);
      if (hasNoChange) {
        return;
      }
      gridApi.setColumnDefs(columnDefs);
    }

    const enrichAndValidateData = (rows, type) => {
      const validateMethod = type === 'PO' ? validatePOData : validateSOData;
      const errorRows = [];
      _.forEach(rows, (row, index) => {
        row.line = index + 1;
        errorRows.push(validateMethod(row));
      });

      setErrorRecords(_.compact(errorRows));
    }

    const buildCsvParserErrors = (rows, errors) => {
      const commonErrors = [];
      errors.forEach(error => {
        if (!_.isNil(error.row)) {
          const row = rows[error.row];
          if (_.isNil(row)) {
            commonErrors.push(error);
            return;
          }
          const validationResults = row.validationResults || [];
          validationResults.push({field: 'line', message: error.message});
          row.validationResults = validationResults;
          return;
        }
        commonErrors.push(error);
      });
      if (_.isEmpty(commonErrors)) {
        return;
      }
      commonErrors.forEach(error => {
        toaster.danger('CSV data format error', {
          description: error.message,
          duration: 10
        });
      });
    };

    let results = [];
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      chunkSize: 2097152, //2 MB
      chunk: function (chunkResults) {
        const csvFields = _.get(chunkResults, 'meta.fields', []);
        buildCsvParserErrors(chunkResults.data, chunkResults.errors);
        buildTableHeaders(csvFields, type);
        enrichAndValidateData(chunkResults.data, type);
        results = [...results, ...chunkResults.data];
        setCsvRecords(results);
      }
    });
  }, [type, file, gridApi]);

  return (
    <Pane>
      <Pane display="flex" flexDirection="row" flexShrink={0} flexBasis="100%" flexWrap="wrap" marginTop={majorScale(2)}>
        <Tablist display="flex" alignItems="center">
          <Strong marginRight={majorScale(1)} color={theme.colors.noissuePurple}>Filters: </Strong>
          {['All', 'Only Errors'].map((tab, index) => (
            <Tab key={index}
                 onSelect={() => onChangeFilter(tab)}
                 isSelected={(!activeFilter && index === 0) || tab === activeFilter}>
              {tab}
            </Tab>
          ))}
        </Tablist>
        <Pane marginLeft="auto" display="flex" alignItems="center">
          {activeFilter === 'All' ?
            <Text size={300}>Displaying {csvRecords.length} records (<Strong color="red">{errorRecords.length} error records</Strong>).</Text>
            : <Text size={300}>Displaying <Strong color="red">{errorRecords.length} error records</Strong> (Total {csvRecords.length} records)</Text>
          }
        </Pane>
      </Pane>
      <Pane height={400} marginTop={20} className="ag-theme-balham">
        <AgGridReact
          reactUi={true}
          rowData={[]}
          onGridReady={onGridReady}
          getRowClass={getRowClass}
          enableBrowserTooltips={true}
          tooltipShowDelay={400}>
        </AgGridReact>
      </Pane>
    </Pane>
  );
};

export default CsvPreviewPane;
