import {makeAutoObservable, runInAction} from 'mobx';
import {API} from 'aws-amplify';
import * as Sentry from '@sentry/react';

export default class CategoryStore {
  state = null;
  error = null;
  _categories = [];

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
  }

  get categories() {
    return this._categories;
  }

  get isLoading() {
    return this.state === 'pending';
  }

  async fetchCategories(force) {
    if ('pending' === this.state) {
      return;
    }

    if ('done' === this.state && this.categories.length !== 0 && !force) {
      return;
    }

    this.error = null;
    this.state = 'pending';

    try {
      const categories = await API.get('noissue', '/categories', {});
      const result = ['All', ...categories];
      runInAction(() => {
        this._categories = result;
        this.state = 'done';
      });
    } catch (e) {
      Sentry.captureException(e);
      runInAction(() => {
        this.state = 'error';
        this.error = e;
      });
    }
  }
}