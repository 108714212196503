import _ from "lodash";
import {Link, Pane, Strong} from "evergreen-ui";
import React from "react";

const ZendeskCustomFieldChangeDataView = (params) => {
  const fieldName = _.get(params, 'data.eventPayload.ticket_event.custom_field.raw_title', '');
  const currentValue = _.get(params, 'data.eventPayload.ticket_event.current', '');
  const previousValue = _.get(params, 'data.eventPayload.ticket_event.previous', '');
  const ticketId = _.get(params, 'data.eventPayload.ticket_event.ticket.id', '');
  const ticketUrl = `https://noissue5226-help.zendesk.com/agent/tickets/${ticketId}`;

  return (
    <Pane display="flex" flexWrap="wrap">
      <Pane flexBasis="max-content" marginRight={20}><Strong>Ticket: </Strong><Link href={ticketUrl} target="_blank">{ticketId}</Link></Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Field: </Strong>{fieldName}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Current Value: </Strong>{currentValue}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Previous Value: </Strong>{previousValue}</Pane>
    </Pane>
  )
};

export default ZendeskCustomFieldChangeDataView;