import {
  Heading,
  Pane,
  Strong,
  Text,
  TextInput,
  UnorderedList,
  ListItem,
  Textarea,
  Button,
  FloppyDiskIcon,
  RemoveIcon,
  majorScale,
  Tab,
  Tablist,
  toaster
} from "evergreen-ui";
import {API} from 'aws-amplify';
import _ from 'lodash';
import {useState, useLayoutEffect, useEffect} from 'react';
import emailValidator from 'email-validator';
import RouterContentPane from "./RouterConentPane";
import { Auth } from 'aws-amplify'

const NotificationConfigForm = ({orderType, eventType, eventName, isHidden = true}) => {
  const [emailAddresses, setEmailAddresses] = useState('');
  const [schedulerAmount, setSchedulerAmount] = useState('');
  const onSubmitForm = async (event) => {
    event.preventDefault();
    const emailAddressArray = _.chain(emailAddresses).split(',').map(_.trim).value();
    const invalidEmailAddresses = emailAddressArray.filter(emailAddress => !emailValidator.validate(emailAddress));
    if (invalidEmailAddresses.length > 0) {
      toaster.danger('Cannot save. There are invalid email addresses', {
        description: invalidEmailAddresses.join(', ')
      });
      return;
    }
    toaster.notify(`Saving ${eventName} notification config`, {
      duration: 1
    });
    try {
      await API.post('noissue', `/notificationConfigs/${orderType}/${eventType}`, {
        body: {schedulerAmount, emailAddresses: emailAddressArray}
      });
      toaster.notify(`Saved ${eventName} notification config`, {
        duration: 1
      });
    } catch (e) {
      toaster.danger('Error!', {
        description: e.message || JSON.stringify(e)
      });
    }
  };

  useLayoutEffect(() => {
    API.get('noissue', `/notificationConfigs/${orderType}/${eventType}`, {})
      .then(config => {
        setSchedulerAmount(config.schedulerAmount || 1);
        setEmailAddresses((config.emailAddresses || []).join(','));
      })
      .catch((err) => {
        toaster.danger(`Cannot load ${eventName} notification config!`, {
          description: err.message
        });
      });
  }, [orderType, eventType, eventName]);

  return (
    <Pane
      paddingLeft={40}
      flex="1"
      role="tabpanel"
      aria-labelledby={eventName}
      aria-hidden={isHidden}
      display={!isHidden ? 'block' : 'none'}>
      <form action={`/notification-config/${orderType}/${eventType}`} method="post" onSubmit={onSubmitForm}>
        <Pane>
          <Strong>I would like to receive notification about "{eventName}" orders</Strong>
          <Pane marginLeft={20}>
            <UnorderedList>
              <ListItem>
                <Text>For every </Text>
                <TextInput type="number"
                           width={90}
                           name='schedulerAmount'
                           max={7 * 24}
                           min={0}
                           value={schedulerAmount}
                           onChange={e => setSchedulerAmount(e.target.value)}/>
                <Text> hours</Text>
              </ListItem>
              <ListItem>
                <Pane>
                  <Text>To these email addresses: </Text>
                </Pane>
                <Textarea name="emailAddresses"
                          placeholder="Input your email addresses here"
                          value={emailAddresses}
                          onChange={e => setEmailAddresses(e.target.value)}/>
                <Pane>
                  <Text color="muted" fontStyle={"italic"}>(comma separated email addresses)</Text>
                </Pane>
              </ListItem>
            </UnorderedList>
          </Pane>
        </Pane>
        <Pane display="flex">
          <Pane marginLeft="auto">
            <Button iconBefore={RemoveIcon}>Cancel</Button>
            <Button iconBefore={FloppyDiskIcon} marginLeft={majorScale(2)} appearance="primary" type="submit">Save
              Config</Button>
          </Pane>
        </Pane>
      </form>
    </Pane>
  );
};

const checkUserBelongGroup = (user, group) => {
  if (!user.signInUserSession.accessToken.payload['cognito:groups']) {
    return false;
  }

  return user.signInUserSession.accessToken.payload['cognito:groups'].some((e) => e.startsWith(`${group}:`));
}

const NotificationConfig = () => {
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState('');
  const [isSupplier, setSupplier] = useState(false);
  const [isShipper, setShipper] = useState(false);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setShipper(checkUserBelongGroup(user, 'shipper'));
        setSupplier(checkUserBelongGroup(user, 'supplier'));
      })
  }, []);

  useEffect(() => {
    const types = [];
    if (isShipper) {
      types.push({orderType: 'SO', eventType: 'readyToShip', eventName: 'Ready To Ship'});
    }
    if (isSupplier) {
      types.push({orderType: 'PO', eventType: 'sentToPrint', eventName: 'Sent To Print'});
    }
    setNotificationTypes(types);
    if (types.length > 0) {
      setSelectedEventType(types[0].eventType);
    }
  }, [isShipper, isSupplier]);

  return (
    <RouterContentPane>
      <Pane width={500}>
        <Heading flexShrink={0} flexBasis="100%" size={800}>Notification Config</Heading>
        <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
          {notificationTypes.map(({eventType, eventName}) => (
            <Tab key={eventType}
                 id={eventType}
                 onSelect={() => setSelectedEventType(eventType)}
                 isSelected={eventType === selectedEventType}
                 aria-controls={`panel-${eventType}`}>
              {eventName}
            </Tab>
          ))}
        </Tablist>
        {notificationTypes.map(({orderType, eventType, eventName}) => (
          <NotificationConfigForm key={eventType}
                                  orderType={orderType}
                                  eventType={eventType}
                                  eventName={eventName}
                                  isHidden={eventType !== selectedEventType}/>
        ))}
      </Pane>
    </RouterContentPane>
  );
}

export default NotificationConfig;
