import OrderStore from './orderStore';
import BatchStore from './batchStore';
import CategoryStore from './categoryStore';
import DlqStore from "../admin/dlq/DlqStore";
import DlqDetailStore from "../admin/dlq/DlqDetailStore";

export class RootStore {
  constructor() {
    this.orderStore = new OrderStore(this);
    this.userStore = new UserStore(this);
    this.batchStore = new BatchStore(this);
    this.categoryStore = new CategoryStore(this);
    this.dlqStore = new DlqStore(this);
    this.dlqDetailStore = new DlqDetailStore(this);
  }
}

export class UserStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
}