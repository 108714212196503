import {
  Pane
} from 'evergreen-ui';
import RouterContentPane from '../RouterConentPane';
import React from 'react';
import SubNav from '../SubNav';
import {Redirect, Route, Switch} from 'react-router-dom';
import Zendesk from './Zendesk';
import Dlq from './Dlq';

const routes = [{
  displayName: 'Zendesk',
  path: '/admin/zendesk'
}, {
  displayName: 'DLQ',
  path: '/admin/dlq'
}]



const Admin = () => {
  return(
    <Pane>
      <SubNav routes={routes} />
      <RouterContentPane>
        <Switch>
          <Route exact path="/admin">
            <Redirect to="/admin/zendesk" />
          </Route>
          <Route exact path="/admin/zendesk">
            <Zendesk />
          </Route>
          <Route exact path="/admin/dlq">
            <Dlq />
          </Route>
        </Switch>
      </RouterContentPane>
    </Pane>
  );
}

export default Admin;