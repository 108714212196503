import _ from "lodash";
import {Pane, Strong} from "evergreen-ui";
import React from "react";

const NoissueRecommendSubscriptionEventDataView = (params) => {
  const customerEmail = _.get(params, 'data.eventPayload.customerEmail', '');

  return (
    <Pane display="flex" flexWrap="wrap">
      <Pane flexBasis="max-content" marginRight={20}><Strong>Customer Email: </Strong>{customerEmail}</Pane>
    </Pane>
  )
};

export default NoissueRecommendSubscriptionEventDataView;