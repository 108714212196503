import _ from "lodash";
import {Pane, Strong} from "evergreen-ui";
import React from "react";

const OrderTrackingStatusDataView = (params) => {
  const reference = _.get(params, 'data.eventPayload.reference', '');
  const status = _.get(params, 'data.eventPayload.status', '');
  const isDelivered = _.get(params, 'data.eventPayload.isDelivered', '').toString();
  const isException = _.get(params, 'data.eventPayload.isException', '').toString();
  const customerEmail = _.get(params, 'data.eventPayload.shippingDetail.customerEmail', '').toString();
  const trackingNumber = _.get(params, 'data.eventPayload.trackingNumber', '');
  return (
    <Pane display="flex" flexWrap="wrap">
      <Pane flexBasis="max-content" marginRight={20}><Strong>Reference: </Strong>{reference}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Status: </Strong>{status}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Delivered: </Strong>{isDelivered}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Exception: </Strong>{isException}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Customer Email: </Strong>{customerEmail}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Tracking Number: </Strong>{trackingNumber}</Pane>
    </Pane>
  );
};

export default OrderTrackingStatusDataView;