import _ from "lodash";
import {Link, Pane, Strong} from "evergreen-ui";
import React from "react";

// const ZENDESK_ORDER_STRUCTURE_FIELD_ID = 900007466326;
const ZENDESK_ORDER_ID_FIELD_ID = 900008095543;

const DesignApprovedCompleteDataView = (params) => {
  const suppliers = _.get(params, 'data.eventPayload.meta.suppliers', []).join(', ');
  const ticketId = _.get(params, 'data.eventPayload.ticket.id', '');
  const ticketUrl = `https://noissue5226-help.zendesk.com/agent/tickets/${ticketId}`;
  const orderId = _.get(params, 'data.eventPayload.ticket.fields', [])
    .filter(field => ZENDESK_ORDER_ID_FIELD_ID === field.id)
    .map(field => field.value)[0] || '';

  // const orderStructure = _.get(params, 'data.eventPayload.ticket.fields', [])
  //   .filter(field => ZENDESK_ORDER_STRUCTURE_FIELD_ID === field.id)
  //   .map(field => field.value)[0] || '';
  // const orderJson = JSON.parse(orderStructure);
  // console.log(orderJson);

  return (
    <Pane display="flex" flexWrap="wrap">
      <Pane flexBasis="max-content" marginRight={20}><Strong>Suppliers: </Strong>{suppliers}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Ticket: </Strong><Link href={ticketUrl} target="_blank">{ticketId}</Link></Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Order ID: </Strong>{orderId}</Pane>
    </Pane>
  )
};

export default DesignApprovedCompleteDataView;