import _ from "lodash";
import {Pane, Strong} from "evergreen-ui";
import React from "react";

const OrderShippedDataView = (params) => {
  const reference = _.get(params, 'data.eventPayload.reference', '');
  const trackingInfo = _.get(params, 'data.eventPayload.trackings', [])
    .map(tracking => (
      <>
        <Pane flexBasis="max-content" marginRight={20}>
          <Strong>Tracking Numbers: </Strong>{tracking.trackingNumbers}
        </Pane>
        <Pane flexBasis="max-content" marginRight={20}>
          <Strong>Carrier: </Strong>{tracking.carrier}
        </Pane>
        <Pane flexBasis="max-content" marginRight={20}>
          <Strong>Customer Email: </Strong>{tracking.customerEmail}
        </Pane>
      </>
    ));
  return (
    <Pane display="flex" flexWrap="wrap">
      <Pane flexBasis="max-content" marginRight={20}><Strong>Reference: </Strong>{reference}</Pane>
      {trackingInfo}
    </Pane>
  )
};

export default OrderShippedDataView;
