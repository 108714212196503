import {Dialog} from 'evergreen-ui';
import {observer} from 'mobx-react-lite';
import ReactJson from 'react-json-view'
import {useStore} from '../../useStore';
import _ from "lodash";

const DetailViewDialog = observer(() => {
  const {dlqDetailStore, dlqStore} = useStore();
  const updateJson = (rjvParams) => {
    dlqDetailStore.updateEventPayload(rjvParams.updated_src);
  };

  const onSubmit = _.debounce(async() => {
    await dlqDetailStore.replayEvent();
    await dlqStore.fetchEvents(true);
  }, 400);

  return (
    <Dialog
      isShown={dlqDetailStore.isShowingDetailViewModel}
      title='Review & Edit event'
      onCloseComplete={dlqDetailStore.closeDetailView}
      onConfirm={onSubmit}
      isConfirmLoading={dlqDetailStore.isSubmittingEvent}
      confirmLabel="Replay Event"
      width="70%">
      <ReactJson
        src={dlqDetailStore.eventPayload}
        onEdit={updateJson}
        onAdd={updateJson}
        onDelete={updateJson}/>
    </Dialog>
  );
});

export default DetailViewDialog;
