import {Badge, CommentIcon, Link, majorScale, minorScale, MoreIcon, Pane, Tooltip} from 'evergreen-ui';
import {observer} from 'mobx-react-lite';

import {useStore} from './useStore';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import FeedbackDialog from './FeedbackDialog';
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import 'ag-grid-community/dist/styles/ag-grid.min.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.min.css';
import './custom-ag-grid.css';
import moment from "moment";

const OrdersTable = observer(() => {
  const [gridApi, setGridApi] = useState(null);
  const {orderStore} = useStore();
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [selectedReference, setSelectedReference] = useState(null);
  const history = useHistory();

  function onMoreClicked(o) {
    history.push(`/manufacturing/orders/${o.reference}`);
  }

  function onSendFeedbackClicked(o) {
    setSelectedReference(o.reference);
    setShowFeedbackDialog(true);
  }

  function onSendFeedbackCloseComplete() {
    setSelectedReference(null);
    setShowFeedbackDialog(false);
  }

  useEffect(() => {
    if (!gridApi) {
      return;
    }
    if (orderStore.state === 'pending') {
      gridApi.showLoadingOverlay();
    } else {
      gridApi.hideOverlay();
    }
  }, [orderStore.state, gridApi]);

  const defaultColDef = {
    // make every column use 'text' filter by default
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressMenu: true,
    sortable: true,
    unSortIcon: true,
    resizable: true
  };

  const dateFormatter = (params) => {
    return moment(params.value, 'YYYY-MM-DD').format('DD MMM YYYY');
  };

  const dateFilterParams = {
    comparator: (searchDate, cellValue) => {
      if (cellValue == null) {
        return 0;
      }
      const cellMoment = moment(cellValue, 'YYYY-MM-DD');
      const searchMoment = moment(searchDate);

      if (cellMoment.isBefore(searchMoment)) {
        return -1;
      } else if (cellMoment.isAfter(searchMoment)) {
        return 1;
      }
      return 0;
    }
  };

  const TagsRenderer = (params) => {
    return (
      <div className="tag-container">
        {params.data.slaDays < 0 && <Badge color="red" marginRight={minorScale(1)}>overdue</Badge>}
        {params.data.reorder && <Badge color="green" marginRight={minorScale(1)}>reorder</Badge>}
        {params.data.reprint && <Badge color="yellow" marginRight={minorScale(1)}>reprint</Badge>}
        {params.data.isInBatch && <Badge color="neutral" marginRight={minorScale(1)}>in batch</Badge>}
      </div>
    );
  };

  const ActionsRenderer = (params) => {
    return (
      <>
        <Link marginRight={majorScale(1)} onClick={() => onMoreClicked(params.data)} color="#ff516e"
              cursor="pointer"><MoreIcon/></Link>
        <Tooltip content="Send feedback">
          <Link onClick={() => onSendFeedbackClicked(params.data)}><CommentIcon/></Link>
        </Tooltip>
      </>
    );
  };

  const LoadingOverlay = () => {
    return (
      <span className="ag-overlay-loading-center">Please wait while data are loading</span>
    );
  };

  const onSelectionChanged = () => {
    orderStore.selectedOrders = gridApi.getSelectedRows();
  };

  const onFilterChanged = (event) => {
    orderStore.displayedRowCount = gridApi.getDisplayedRowCount();
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    orderStore.selectedOrders = [];
  };

  return (
    <Pane flexBasis="100%" height='50vh' marginTop={majorScale(1)} className="ag-theme-balham">
      <AgGridReact
        reactUi={true}
        rowHeight={50}
        defaultColDef={defaultColDef}
        rowData={orderStore.orders}
        onGridReady={onGridReady}
        suppressRowClickSelection={true}
        enableCellTextSelection={true}
        rowSelection={'multiple'}
        onSelectionChanged={onSelectionChanged}
        onFilterChanged={onFilterChanged}
        frameworkComponents={{
          tagsRenderer: TagsRenderer,
          actionsRenderer: ActionsRenderer,
          loadingOverlay: LoadingOverlay
        }}
        loadingOverlayComponent={'loadingOverlay'}>
        <AgGridColumn field="reference"
                      headerName="PO ref."
                      width={150}
                      headerCheckboxSelection={true}
                      headerCheckboxSelectionFilteredOnly={true}
                      checkboxSelection={true}/>
        <AgGridColumn field="relatedPOCount"
                      headerName="#POs"
                      width={80}
                      filter="agNumberColumnFilter"/>
        <AgGridColumn field="purchaseOrderStatus"
                      headerName="PO status"
                      width={100}/>
        <AgGridColumn field="salesOrderReference"
                      headerName="SO ref."
                      width={130}/>
        <AgGridColumn field="category"
                      headerName="Category"
                      width={100}/>
        <AgGridColumn field="estimatedDeliveryDate"
                      headerName="Due date"
                      width={100}
                      valueFormatter={dateFormatter}
                      filter="agDateColumnFilter"
                      filterParams={dateFilterParams}/>
        <AgGridColumn field="deliveryCountry"
                      headerName="Delivery country"
                      width={100}/>
        <AgGridColumn field="slaDays"
                      headerName="SLA days"
                      width={80}
                      filter="agNumberColumnFilter"/>
        <AgGridColumn field="fulfillmentSlaDays"
                      headerName="Fulfillment SLA days"
                      width={110}
                      filter="agNumberColumnFilter"/>
        <AgGridColumn field="createdDate"
                      headerName="Created at"
                      width={100}
                      valueFormatter={dateFormatter}
                      filter="agDateColumnFilter"
                      filterParams={dateFilterParams}/>
        <AgGridColumn field="supplierCode"
                      headerName="Supplier Code"
                      width={100}/>
        <AgGridColumn field="tags"
                      headerName="Tags"
                      width={170}
                      cellRenderer="tagsRenderer"/>
        <AgGridColumn headerName=""
                      width={70}
                      cellRenderer="actionsRenderer"
                      sortable={false}
                      filter={null}/>
      </AgGridReact>
      <FeedbackDialog isShown={showFeedbackDialog} onCloseComplete={onSendFeedbackCloseComplete}
                      orderReference={selectedReference}/>
    </Pane>
  );
});

export default OrdersTable;