import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'

import { Auth, Hub } from 'aws-amplify'
import { signInButton, signInButtonContent } from '@aws-amplify/ui'

function explodeRole(role) {
    let roleParts = role.split(':');
    if (roleParts.length < 3) {
        roleParts = roleParts.concat(new Array(3 - roleParts.length).fill('*'));
    }
    return roleParts;
}

function isEqualOrWildcard(value1, value2) {
    return value1 === value2 || value1 === '*' || value2 === '*';
}

/**
 * Handle user authentication and related features.
 *
 * @returns {{
 *   isAuthenticated: boolean,
 *   user: CognitoUser,
 *   error: any,
 *   signIn: function,
 *   signOut: function,
 *   SignInButton: React.Component,
 *   isSupplier: boolean,
 *   suppliedId: any
 * }}
 *
 * @see https://aws-amplify.github.io/amplify-js/api/classes/authclass.html
 * @see https://aws-amplify.github.io/amplify-js/api/classes/hubclass.html
 * @see https://aws-amplify.github.io/docs/js/hub#listening-authentication-events
 * @see https://github.com/aws-amplify/amplify-js/blob/master/packages/amazon-cognito-identity-js/src/CognitoUser.js
 */
const useAuthentication = () => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSupplier, setIsSupplier] = useState(false);
    const [supplierId, setSupplierId] = useState(null);

    function isPermitted(requiredRole) {
        if (!requiredRole) {
            return true;
        }
        const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
        const permittedGroup = groups.find(g => {
            const [requiredResource, requiredAction, requiredId] = explodeRole(requiredRole);
            const [ownResource, ownAction, ownId] = explodeRole(g);

            return isEqualOrWildcard(requiredResource, ownResource)
              && isEqualOrWildcard(requiredAction, ownAction)
              && isEqualOrWildcard(requiredId, ownId);
        });
        if (permittedGroup) {
            return true;
        } else {
            return false;
        }
    }

    const refreshState = useCallback(() => {
        setIsLoading(true);

        Auth.currentAuthenticatedUser()
            .then(user => {
                setUser(user);
                const isAuthenticatedResult = _isAuthenticated(user);
                setIsAuthenticated(isAuthenticatedResult);
                if (isAuthenticatedResult) {
                    const isSupplierResult = _isSupplier(user);
                    setIsSupplier(isSupplierResult);
                    if (isSupplierResult) {
                        const supplierId = user.signInUserSession.accessToken.payload['cognito:groups']
                            .find((e) => e.startsWith('supplier:')).split(':')[1];
                        setSupplierId(supplierId);
                    }
                }
                setError(null);
                setIsLoading(false);
            })
            .catch(err => {
                setUser(null);
                setIsAuthenticated(false);
                setIsSupplier(false);
                if (err === 'The user is not authenticated') {
                    setError(null);
                } else {
                    setError(err);
                }
                setIsLoading(false);
            })
    }, [])

    // Make sure our state is loaded before first render
    useLayoutEffect(() => {
        refreshState();
    }, [refreshState])

    // Subscribe to auth events
    useEffect(() => {
        const handler = ({ payload }) => {
            switch (payload.event) {
                case 'configured':
                case 'signIn':
                case 'signIn_failure':
                case 'signOut':
                    refreshState()
                    break

                default:
                    break
            }
        }

        Hub.listen('auth', handler)

        return () => {
            Hub.remove('auth', handler)
        }
    }, [refreshState])

    const signIn = useCallback(() => {
        Auth.federatedSignIn({ provider: 'COGNITO' }).catch(err => {
            setError(err);
        })
    }, [])

    const signOut = useCallback(() => {
        Auth.signOut()
            .then(_ => refreshState())
            .catch(err => {
                setError(err);
            })
    }, [refreshState])

    const CognitoSignInButton = useCallback(
        ({ label = 'Sign In' }) => (
            <button className={signInButton} onClick={signIn}>
                <span className={signInButtonContent}>{label}</span>
            </button>
        ),
        [signIn]
    )

    return {
        isAuthenticated,
        isLoading,
        user,
        error,
        signIn,
        signOut,
        SignInButton: CognitoSignInButton,
        isSupplier,
        supplierId,
        isPermitted
    }
}

const _isAuthenticated = user => {
    if (
        !user ||
        !user.signInUserSession ||
        !user.signInUserSession.isValid ||
        !user.signInUserSession.accessToken ||
        !user.signInUserSession.accessToken.getExpiration
    ) {
        return false
    }

    const session = user.signInUserSession
    const isValid = session.isValid() || false

    const sessionExpiry = new Date(session.accessToken.getExpiration() * 1000)
    const isExpired = new Date() > sessionExpiry

    return isValid && !isExpired
}

const _isSupplier = user => {
    if (!user.signInUserSession.accessToken.payload['cognito:groups']) {
        return false;
    }

    return user.signInUserSession.accessToken.payload['cognito:groups'].some((e) => e.startsWith('supplier:'));
}

export default useAuthentication