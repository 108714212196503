import {
  Dialog,
  majorScale,
  Pane,
  Text,
  TextInputField
} from 'evergreen-ui';

const BatchDialog = ({
  onConfirm,
  isShown,
  title,
  isCreating,
  batchName,
  setBatchName,
  onCloseComplete
}) => {
  return (
    <Dialog
      isShown={isShown}
      title={title}
      onConfirm={onConfirm}
      onCloseComplete={onCloseComplete}
      isConfirmLoading={isCreating}
      hasCancel={!isCreating}
      hasClose={!isCreating}
      shouldCloseOnOverlayClick={!isCreating}
      shouldCloseOnEscapePress={!isCreating}
      confirmLabel={isCreating ? 'Creating...' : 'Create'}
    >
      <Pane>
        <Text>Create a batch to prepare selected orders for download</Text>
        <TextInputField
          label=""
          marginTop={majorScale(1)}
          marginBottom={0}
          description="Batch name"
          value={batchName}
          onChange={e => setBatchName(e.target.value)}
        />
      </Pane>
    </Dialog>
  );
}

export default BatchDialog;