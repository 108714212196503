import {
  minorScale,
  Pane,
  Select,
  Heading,
} from 'evergreen-ui';
import {observer} from 'mobx-react-lite';
import {useStore} from './useStore';

const pageSizes = [10, 20, 50, 100, 250, 500, 2500];

const Paginator = observer((props) => {
  const {orderStore} = useStore();

  function onPageSizeChange(e) {
    orderStore.setPageSize(pageSizes[e.target.selectedIndex]);
  }

  function resultsLabel() {
    if (orderStore.orders.length === orderStore.displayedRowCount) {
      switch(orderStore.orders.length) {
        case 1:
          return 'Displaying 1 result';
        default:
          return `Displaying ${orderStore.orders.length} results`;
      }
    } else {
      switch(orderStore.orders.length) {
        case 1:
          return `Displaying ${orderStore.displayedRowCount}/1 result`;
        default:
          return `Displaying ${orderStore.displayedRowCount}/${orderStore.orders.length} results`;
      }
    }
  }

  return(
    <Pane display="flex" alignItems="center" flex="0 0 250px" justifyContent="space-around" {...props}>
      <Heading fontWeight={400} size={200} paddingTop={minorScale(1)}>{resultsLabel()}</Heading>
      <Select display="flex" flex="0 0 65px" value={orderStore.query.pageSize} onChange={onPageSizeChange}>
        {pageSizes.map((p) =>
          <option value={p} key={p}>{p}</option>
        )}
      </Select>
    </Pane>
  );
});

export default Paginator;