import _ from "lodash";
import React from "react";
import {Pane, Strong} from 'evergreen-ui';

const orderShipBobCancelledIfAnyDataView = (params) => {
  const orderId = _.get(params, 'data.eventPayload.order', '');
  const customerEmail = _.get(params, 'data.eventPayload.customerEmail', '');
  const modifiedDate = _.get(params, 'data.eventPayload.modifiedDate', '');
  return (
    <Pane display="flex" flexWrap="wrap">
      <Pane flexBasis="max-content" marginRight={20}><Strong>Order: </Strong>{orderId}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Customer Email: </Strong>{customerEmail}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Modified Date: </Strong>{modifiedDate}</Pane>
    </Pane>
  )
}

export default orderShipBobCancelledIfAnyDataView;