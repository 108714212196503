import {
  Heading,
  majorScale,
  Pane,
  DownloadIcon,
  SearchInput,
  Text,
  FilePicker,
  Dialog,
  Strong,
  Combobox,
  toaster
} from 'evergreen-ui';

import {
  useLayoutEffect, useState
} from 'react';

import moment from "moment";
import debounce from 'lodash/debounce';

import {useStore} from './useStore';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import Paginator from './Paginator';
import {autorun, runInAction} from 'mobx';
import {useLocation} from 'react-router';
import OrdersTable from './OrdersTable';
import OrdersTableControls from './OrdersTableControls';
import BatchDialog from './BatchDialog';
import Filters from './Filters';
import theme from './theme';
import CsvPreviewPane from './CsvPreviewPane';

const Orders = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const {orderStore, batchStore, categoryStore} = useStore();
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [batchName, setBatchName] = useState(null);
  const [batchCreating, setBatchCreating] = useState(false);
  const [batchUpdating, setBatchUpdating] = useState(false);
  const [isValidForUpload, setValidForUpload] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [file, setFile] = useState(null);

  runInAction(() => {
    orderStore.query = Object.fromEntries(new URLSearchParams(location.search));
  });

  useLayoutEffect(() => {
    orderStore.setOrderType('PO');
    batchStore.setOrderType('PO');
    orderStore.fetchOrders();
    categoryStore.fetchCategories(false);
  }, [orderStore, batchStore, categoryStore]);

  autorun(() => {
    if (orderStore.queryString !== location.search.substring(1)) {
      history.push({search: orderStore.queryString});
    }
  });

  function bulkDownloadOnSelect() {
    const defaultBatchName = moment().format('YYYY-MM-DD-HH:mm') ;
    setBatchName(defaultBatchName);
    setShowDownloadModal(true);
  }

  async function createBatch() {
    setBatchCreating(true);
    const orderRefs = orderStore.selectedOrders.map(o => o.reference);
    await batchStore.createBatch({
      id: batchName,
      orderRefs
    });
    setBatchCreating(false);
    setShowDownloadModal(false);
    history.push('/manufacturing/batches');
  }

  const bulkActions = [{
    label: 'Download...',
    action: bulkDownloadOnSelect,
    icon: <DownloadIcon/>
  }];

  function onStatusFilterChanged(e) {
    orderStore.setQueryParam('status', e === 'All' ? '' : e);
  }

  function onFilterChanged(e) {
    orderStore.setQueryParam('inBatch', e === 'Not in batch' ? false : null);
  }

  function onSearchChanged(query) {
    orderStore.setQueryParam('q', query);
  }

  function onCategoryChanged(selected) {
    orderStore.setQueryParam('category', selected === 'All' ? null : selected);
  }

  async function bulkUpdateAction() {
    setShowUpdateModal(true);
  }

  function onFileChange(files) {
    setFile(files[0]);
  }

  async function onUploadConfirm(close) {
    try {
      setBatchUpdating(true);
      await orderStore.bulkUpdate(file, 'PO');
    } finally {
      if (!orderStore.error) {
        close();
      } else {
        toaster.danger('Error!', {
          description: orderStore.error.response.data.message || orderStore.error.message
        });
      }
      setBatchUpdating(false);
    }
  }

  return (
    <Pane display="flex" flexBasis="100%" flexWrap="wrap">
      <Heading flexShrink={0} flexBasis="100%" size={800}>Purchase Orders</Heading>
      <Pane display="flex" flexBasis="100%" flexDirection="row">
        <Filters title="Filters"
                 filters={['None', 'Not in batch']}
                 onFilterChanged={onFilterChanged}
                 activeFilter={orderStore.query.inBatch === false ? 'Not in batch' : 'None'}
        />
      </Pane>
      <Pane display="flex" flexBasis="100%" flexDirection="row" justifyContent="space-between">
        <Filters onFilterChanged={onStatusFilterChanged}
                 title="Status"
                 activeFilter={orderStore.query.status}
                 filters={['All', 'Sent To Print', 'Print Underway', 'Print Complete']}/>
        <Pane display="flex" alignSelf="flex-end" marginRight={majorScale(2)} alignItems="center">
          <Strong color={theme.colors.noissuePurple} marginRight={majorScale(1)}>Category</Strong>
          <Combobox
            onChange={onCategoryChanged}
            items={categoryStore.categories}
            initialSelectedItem={orderStore.query.category ? orderStore.query.category : 'All'}/>
        </Pane>
        <SearchInput display="flex"
                     alignSelf="flex-end"
                     placeholder="search orders"
                     onChange={debounce(e => onSearchChanged(e.target.value), 300)}
                     defaultValue={orderStore.query.q ? orderStore.query.q : ''}/>
      </Pane>
      <OrdersTableControls bulkActions={bulkActions} bulkUpdateAction={bulkUpdateAction}/>
      <OrdersTable />
      <Pane display="flex" flexBasis="100%" justifyContent="flex-end" marginTop={majorScale(1)}>
        <Paginator/>
      </Pane>
      <BatchDialog
        batchName={batchName}
        setBatchName={setBatchName}
        title={`Create batch (${orderStore.selectedOrders.length} selected)`}
        isShown={showDownloadModal}
        onCloseComplete={() => setShowDownloadModal(false)}
        isCreating={batchCreating}
        onConfirm={createBatch}
      />
      <Dialog isShown={showUpdateModal}
              title="Bulk update"
              confirmLabel="Upload"
              isConfirmLoading={batchUpdating}
              isConfirmDisabled={!isValidForUpload}
              hasClose={!batchUpdating}
              shouldCloseOnOverlayClick={!batchUpdating}
              shouldCloseOnEscapePress={!batchUpdating}
              onConfirm={onUploadConfirm}
              onCloseComplete={() => setShowUpdateModal(false)}
              width="80%">
        <Text size={300}>Select a CSV file to bulk update orders.</Text>
        <FilePicker marginTop={majorScale(1)}
                    disabled={batchUpdating}
                    onChange={onFileChange}
                    width={400}
                    accept=".csv,text/csv"
                    placeholder="select CSV file"/>
        <CsvPreviewPane file={file} type="PO" setValidForUploadFn={setValidForUpload}/>
      </Dialog>
    </Pane>
  );
});

export default Orders;