import _ from "lodash";
import {Pane, Strong} from "evergreen-ui";
import React from "react";

const MobileDesignerReminderEmailEventDataView = (params) => {
  const customerEmail = _.get(params, 'data.eventPayload.customerEmail', '');
  const productNames = _.get(params, 'data.eventPayload.payload.productName', []).join(', ');

  return (
    <Pane display="flex" flexWrap="wrap">
      <Pane flexBasis="max-content" marginRight={20}><Strong>Customer Email: </Strong>{customerEmail}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Product Names: </Strong>{productNames}</Pane>
    </Pane>
  )
};

export default MobileDesignerReminderEmailEventDataView;