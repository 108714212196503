import OrderShippingAddressUpdatedDataView from './orderShippingAddressUpdatedDataView';
import StoreOrderUpdatedDataView from './storeOrderUpdatedDataView';
import OrderShippedDataView from "./orderShippedDataView";
import OrderTrackingStatusDataView from './orderTrackingStatusDataView';
import DesignApprovedCompleteDataView from './designApprovedCompleteDataView';
import StoreCartConvertedDataView from './storeCartConvertedDataView';
import StoreOrderArchivedDataView from "./storeOrderArchivedDataView";
import DesignFilesStoredDataView from './designFilesStoredDataView';
import FulfilmentReadyDataView from './fulfilmentReadyDataView';
import MobileDesignerReminderEmailEventDataView from './mobileDesignerReminderEmailEventDataView';
import ZendeskCustomFieldChangeDataView from './zendeskCustomFieldChangeDataView';
import StockedSalesOrderCreationCompleteDataView from "./stockedSalesOrderCreationCompleteDataView";
import OrderShipBobCancelledIfAnyDataView from "./orderShipBobCancelledIfAnyDataView";
import OrderPrintingDataView from "./orderPrintingDataView";
import OrderCreationCompleteDataView from "./orderCreationCompleteDataView";
import OrderCreatedDataView from "./orderCreatedDataView";
import OrderCin7CancelledDataView from "./orderCin7CancelledDataView";
import NoissueRecommendSubscriptionEventDataView from "./noissueRecommendSubscriptionEventDataView";
import Cin7AsyncUpdateDataView from "./cin7AsyncUpdateDataView";
import DefaultDataView from './defautlDataView';
import _ from "lodash";

const DataViewRenderer = (params) => {
  const eventType = _.get(params, 'data.detailType', '');
  switch (eventType) {
    case 'OrderShipped':
      return OrderShippedDataView(params);
    case 'OrderShippingAddressUpdated':
      return OrderShippingAddressUpdatedDataView(params);
    case 'OrderTrackingStatus':
      return OrderTrackingStatusDataView(params);
    case 'DesignApprovedComplete':
      return DesignApprovedCompleteDataView(params);
    case 'store/order/updated':
      return StoreOrderUpdatedDataView(params);
    case 'store/order/archived':
      return StoreOrderArchivedDataView(params);
    case 'store/cart/converted':
      return StoreCartConvertedDataView(params);
    case 'DesignFilesStored':
      return DesignFilesStoredDataView(params);
    case 'FulfilmentReady':
      return FulfilmentReadyDataView(params);
    case 'MobileDesignerReminderEmailEvent':
      return MobileDesignerReminderEmailEventDataView(params);
    case 'Support Ticket: Custom Field Changed':
      return ZendeskCustomFieldChangeDataView(params);
    case 'StockedSalesOrderCreationComplete':
      return StockedSalesOrderCreationCompleteDataView(params);
    case 'OrderShipBobCancelledIfAny':
      return OrderShipBobCancelledIfAnyDataView(params);
    case 'OrderPrinting':
      return OrderPrintingDataView(params);
    case 'OrderCreationComplete':
      return OrderCreationCompleteDataView(params);
    case 'OrderCreated':
      return OrderCreatedDataView(params);
    case 'OrderCin7Cancelled':
      return OrderCin7CancelledDataView(params);
    case 'NoissueRecommendSubscriptionEvent':
      return NoissueRecommendSubscriptionEventDataView(params);
    case 'Cin7AsyncUpdate':
      return Cin7AsyncUpdateDataView(params);
    default:
      return DefaultDataView(params);
  }
};

export default DataViewRenderer;
