import _ from "lodash";
import {Pane, Strong} from "evergreen-ui";
import React from "react";

const OrderCin7CancelledDataView = (params) => {
  const orderId = _.get(params, 'data.eventPayload.order', '');
  const createdDate = _.get(params, 'data.eventPayload.createdDate', '');
  const customerEmail = _.get(params, 'data.eventPayload.customerEmail', '');

  return (
    <Pane display="flex" flexWrap="wrap">
      <Pane flexBasis="max-content" marginRight={20}><Strong>Order ID: </Strong>{orderId}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Created Date: </Strong>{createdDate}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Customer Email: </Strong>{customerEmail}</Pane>
    </Pane>
  )
};

export default OrderCin7CancelledDataView;