import _ from "lodash";
import moment from "moment";
import {Pane, Strong} from "evergreen-ui";
import React from "react";

const OrderShippingAddressUpdatedDataView = (params) => {
  const orderId = _.get(params, 'data.eventPayload.order');
  const deliveryAddress1 = _.get(params, 'data.eventPayload.deliveryAddress1');
  const deliveryCity = _.get(params, 'data.eventPayload.deliveryCity');
  const deliveryCountry = _.get(params, 'data.eventPayload.deliveryCountry');
  const updatedTime = _.get(params, 'data.eventPayload.updateTimestamp', null);
  const updatedTimeInStr = !!updatedTime ? moment(updatedTime).format('YYYY-MM-DD HH:mm:ss') : '';
  return (
    <Pane display="flex" flexWrap='wrap'>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Order ID: </Strong>{orderId}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Delivery Address: </Strong>{deliveryAddress1}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Delivery City: </Strong>{deliveryCity}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Delivery Country: </Strong>{deliveryCountry}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Updated Time: </Strong>{updatedTimeInStr}</Pane>
    </Pane>
  )
};

export default OrderShippingAddressUpdatedDataView;
