import {Heading, Pane} from 'evergreen-ui';
import theme from '../theme';
import React, {useLayoutEffect} from 'react';
import {observer} from "mobx-react-lite";
import {useStore} from '../useStore';
import 'ag-grid-community/dist/styles/ag-grid.min.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.min.css';
import DetailViewDialog from './dlq/DetailViewDialog';
import DlqTable from './dlq/DlqTable';
import ReportChart from './dlq/ReportChart';

const Dlq = observer(() => {
  const {dlqStore} = useStore();
  useLayoutEffect(() => {
    dlqStore.fetchEvents();
  });


  return (
    <Pane display="flex" flexBasis="100%" flexWrap="wrap">
      <Heading flexShrink={0} flexBasis="100%" size={800} color={theme.colors.noissuePurple}>DLQ</Heading>
      <Pane display="flex" flexBasis="100%" flexDirection="row">
        <ReportChart/>
      </Pane>
      <Pane display="flex" flexBasis="100%" flexDirection="row">
        <DlqTable />
      </Pane>
      <DetailViewDialog />
    </Pane>
  )
});

export default Dlq;