import {
  Alert,
  Button,
  Heading,
  majorScale,
  Pane,
  SearchInput,
  Select,
  Spinner,
  Strong,
  Text,
  TextInput, toaster
} from 'evergreen-ui';
import theme from '../theme';
import React, {useEffect, useState} from 'react';
import {API} from 'aws-amplify';

const Address = ({prefix, order, onChange, disabled}) => {
  return (
    <React.Fragment>
      <Pane display="flex" flexBasis="100%" marginTop={majorScale(2)}>
        <Strong flexShrink={0} flexBasis={150} textTransform="capitalize">company</Strong>
        <TextInput disabled={disabled} flexShrink={0} flexGrow={0} value={order[`${prefix}Company`]} onChange={e => onChange(`${prefix}Company`, e.target.value)}/>
      </Pane>
      <Pane display="flex" flexBasis="100%" marginTop={majorScale(2)}>
        <Strong flexShrink={0} flexBasis={150} textTransform="capitalize">first name</Strong>
        <TextInput disabled={disabled} flexShrink={0} flexGrow={0} value={order[`${prefix}FirstName`]} onChange={e => onChange(`${prefix}FirstName`, e.target.value)}/>
      </Pane>
      <Pane display="flex" flexBasis="100%" marginTop={majorScale(2)}>
        <Strong flexShrink={0} flexBasis={150} textTransform="capitalize">last name</Strong>
        <TextInput disabled={disabled} flexShrink={0} flexGrow={0} value={order[`${prefix}LastName`]} onChange={e => onChange(`${prefix}LastName`, e.target.value)}/>
      </Pane>
      <Pane display="flex" flexBasis="100%" marginTop={majorScale(2)}>
        <Strong flexShrink={0} flexBasis={150} textTransform="capitalize">address 1</Strong>
        <TextInput disabled={disabled} flexShrink={0} flexGrow={0} value={order[`${prefix}Address1`]} onChange={e => onChange(`${prefix}Address1`, e.target.value)}/>
      </Pane>
      <Pane display="flex" flexBasis="100%" marginTop={majorScale(2)}>
        <Strong flexShrink={0} flexBasis={150} textTransform="capitalize">address 2</Strong>
        <TextInput disabled={disabled} flexShrink={0} flexGrow={0} value={order[`${prefix}Address2`]} onChange={e => onChange(`${prefix}Address2`, e.target.value)}/>
      </Pane>
      <Pane display="flex" flexBasis="100%" marginTop={majorScale(2)}>
        <Strong flexShrink={0} flexBasis={150} textTransform="capitalize">city</Strong>
        <TextInput disabled={disabled} flexShrink={0} flexGrow={0} value={order[`${prefix}City`]} onChange={e => onChange(`${prefix}City`, e.target.value)}/>
      </Pane>
      <Pane display="flex" flexBasis="100%" marginTop={majorScale(2)}>
        <Strong flexShrink={0} flexBasis={150} textTransform="capitalize">state</Strong>
        <TextInput disabled={disabled} flexShrink={0} flexGrow={0} value={order[`${prefix}State`]} onChange={e => onChange(`${prefix}State`, e.target.value)}/>
      </Pane>
      <Pane display="flex" flexBasis="100%" marginTop={majorScale(2)}>
        <Strong flexShrink={0} flexBasis={150} textTransform="capitalize">postal code</Strong>
        <TextInput disabled={disabled} flexShrink={0} flexGrow={0} value={order[`${prefix}PostalCode`]} onChange={e => onChange(`${prefix}PostalCode`, e.target.value)}/>
      </Pane>
      <Pane display="flex" flexBasis="100%" marginTop={majorScale(2)}>
        <Strong flexShrink={0} flexBasis={150} textTransform="capitalize">country</Strong>
        <TextInput disabled={disabled} flexShrink={0} flexGrow={0} value={order[`${prefix}Country`]} onChange={e => onChange(`${prefix}Country`, e.target.value)}/>
      </Pane>
    </React.Fragment>
  );
}

const Row = ({label, children}) => {
  return (
    <Pane display="flex" flexBasis="100%" marginTop={majorScale(2)}>
      <Strong flexShrink={0} flexBasis={150}>{label}</Strong>
      {children}
    </Pane>
  )
}

const Zendesk = () => {
  const [searchInput, setSearchInput] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [updatedOrderStructure, setUpdatedOrderStructure] = useState(null);
  const [parentEcommerceOrder, setParentEcommerceOrder] = useState(null);
  const [linkedPurchaseOrders, setLinkedPurchaseOrders] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [soStageOptions, setSOStageOptions] = useState(null);
  const [poStageOptions, setPOStageOptions] = useState(null);

  useEffect(() => {
    if (!updatedOrderStructure) {
      return;
    }

    if (!soStageOptions) {
      setSOStageOptions(getOrderStageOptions(updatedOrderStructure));
    }

    if (updatedOrderStructure.linkedPurchaseOrders && !poStageOptions) {
      const newPoStageOptions = updatedOrderStructure.linkedPurchaseOrders.reduce((acc, po) => {
        acc[po.purchaseOrderRef] = getOrderStageOptions(po);
        return acc;
      }, {});
      setPOStageOptions(newPoStageOptions);
    }

    setParentEcommerceOrder(updatedOrderStructure.parentEcommerceOrder);
    setLinkedPurchaseOrders(updatedOrderStructure.linkedPurchaseOrders);
  }, [updatedOrderStructure, poStageOptions, soStageOptions]);

  async function viewClicked() {
    setIsLoading(true);
    setTicket(null);
    setParentEcommerceOrder(null);
    setUpdatedOrderStructure(null);
    setPOStageOptions(null);
    setSOStageOptions(null);
    try {
      const ticketResult = await API.get('noissue', `/admin/tickets/${searchInput}`);
      const orderStructureJson = ticketResult.custom_fields.find(f => f.id === 900007466326).value;
      ticketResult.orderStatus = ticketResult.custom_fields.find(f => f.id === 900007414123).value;
      setUpdatedOrderStructure(JSON.parse(orderStructureJson));
      setTicket(ticketResult);
    } catch (e) {
      let message;
      if (e.response && e.response.data) {
        message = e.response.data.message
      } else {
        message = e.message
      }
      toaster.danger('Unexpected error', {
        description: message,
        duration: 300
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function saveClicked() {
    setIsLoading(true);
    try {
      await API.put('noissue', `/admin/tickets/${searchInput}`, {
        body: updatedOrderStructure
      });
      toaster.success('Order updated');
    } catch (e) {
      let message;
      if (e.response && e.response.data) {
        message = e.response.data.message
      } else {
        message = e.message
      }
      toaster.danger('Unexpected error', {
        description: message,
        duration: 300
      });
    } finally {
      setIsLoading(false);
    }
  }

  function getOrderStageOptions(order) {
    const stages = [order.stage];
    if (order.stage !== 'Cancelled') {
      stages.push('Cancelled');
    }
    return stages;
  }

  function setSalesOrderStage(value) {
    if (value === 'Cancelled' && updatedOrderStructure.linkedPurchaseOrders) {
      updatedOrderStructure.linkedPurchaseOrders.forEach(po => {
        setPurchaseOrderStage(po.purchaseOrderRef, 'Cancelled');
      });
    }
    setUpdatedOrderStructure({
      ...updatedOrderStructure,
      stage: value
    })
  }

  function setPurchaseOrderStage(ref, value) {
    const newLinkedPurchaseOrders = updatedOrderStructure.linkedPurchaseOrders.map(po => {
      if (po.purchaseOrderRef === ref) {
        po.stage = value;
      }
      return po;
    })
    setUpdatedOrderStructure({
      ...updatedOrderStructure,
      linkedPurchaseOrders: newLinkedPurchaseOrders
    })
  }

  async function searchKeyPressed(e) {
    if (e.charCode === 13) {
      await viewClicked();
    }
  }

  return (
    <Pane display="flex" flexBasis="100%" flexWrap="wrap">
      <Heading flexShrink={0} flexBasis="100%" size={800} color={theme.colors.noissuePurple}>Edit Zendesk ticket</Heading>
      <Pane display="flex" flexBasis="100%" flexDirection="row" marginTop={majorScale(1)}>
        <SearchInput disabled={isLoading} placeholder="Zendesk ticket ID" width={200} onChange={e => setSearchInput(e.target.value)} onKeyPress={searchKeyPressed}/>
        <Button width={60} disabled={isLoading || !searchInput} marginLeft={majorScale(1)} onClick={viewClicked}>{isLoading ? <Spinner size={18}/> : 'View'}</Button>
      </Pane>
      {ticket &&
      <Pane elevation={1} marginTop={majorScale(2)} display="flex" flexBasis="100%" flexDirection="column" padding={majorScale(2)}>
        <Pane display="flex" flexBasis="100%">
          <a href={`https://noissue5226-help.zendesk.com/agent/tickets/${ticket.id}`} target="_blank" rel="noreferrer">
            <Heading size={600}>{ticket.subject}</Heading>
          </a>
        </Pane>
        {ticket.status === 'closed' &&
        <Alert marginTop={majorScale(1)} appearance="card" intent="danger" title="Cannot update a closed ticket"/>
        }
        <Pane display="flex" flexBasis="100%" marginTop={majorScale(2)}>
          <Strong flexShrink={0} flexBasis={150}>Type</Strong>
          <Text flexShrink={0} flexGrow={0}>{ticket.type}</Text>
        </Pane>
        <Pane display="flex" flexBasis="100%" marginTop={majorScale(2)}>
          <Strong flexShrink={0} flexBasis={150}>Ticket Status</Strong>
          <Text flexShrink={0} flexGrow={0}>{ticket.status}</Text>
        </Pane>
        {parentEcommerceOrder &&
        <Pane display="flex" flexBasis="100%" marginTop={majorScale(2)}>
          <Strong flexShrink={0} flexBasis={150}>Order Status</Strong>
          <Text flexShrink={0} flexGrow={0}>{ticket.orderStatus}</Text>
        </Pane>
        }
        {parentEcommerceOrder &&
        <Pane display="flex" flexBasis="100%">
          <Pane display="flex" flexBasis="50%" flexDirection="column">
            <Pane display="flex" marginTop={majorScale(2)}>
              <Heading size={600}>Billing Address</Heading>
            </Pane>
            <Address disabled={isLoading || ticket.status === 'closed'} prefix="billing"
                     order={parentEcommerceOrder} onChange={(field, value) => {
              const updated = {
                ...parentEcommerceOrder,
                [field]: value
              }
              setUpdatedOrderStructure({
                ...updatedOrderStructure,
                parentEcommerceOrder: updated
              });
            }}/>
          </Pane>
          <Pane display="flex" flexDirection="column">
            <Pane display="flex" marginTop={majorScale(2)}>
              <Heading size={600}>Delivery Address</Heading>
            </Pane>
            <Address disabled={isLoading || ticket.status === 'closed'} prefix="delivery"
                     order={parentEcommerceOrder} onChange={(field, value) => {
              const updated = {
                ...parentEcommerceOrder,
                [field]: value
              }
              setUpdatedOrderStructure({
                ...updatedOrderStructure,
                [field]: value,
                parentEcommerceOrder: updated
              });
            }}/>
          </Pane>
        </Pane>
        }
        {linkedPurchaseOrders &&
        <Pane display="flex" flexBasis="100%" flexDirection="column">
          <Pane display="flex" marginTop={majorScale(2)} flexBasis="100%">
            <Heading size={600}>Order Statuses</Heading>
          </Pane>
          <Row label={updatedOrderStructure.reference.split('-')[1]}>
            <Select value={updatedOrderStructure.stage} onChange={e => setSalesOrderStage(e.target.value)} width={240} flex="none">
              {soStageOptions.map(o => (
                <option value={o} key={o}>{o}</option>
              ))}
            </Select>
          </Row>
          {linkedPurchaseOrders.map(po => (
            <Row key={po.purchaseOrderRef} label={`${po.purchaseOrderRef.split('-')[1]} (${po.category})`}>
              <Select disabled={updatedOrderStructure.stage === 'Cancelled'} value={po.stage} onChange={e => setPurchaseOrderStage(po.purchaseOrderRef, e.target.value)} width={240} flex="none">
                {poStageOptions[po.purchaseOrderRef].map(o => (
                  <option value={o} key={o}>{o}</option>
                ))}
              </Select>
            </Row>
          ))}
        </Pane>
        }
        <Pane marginTop={majorScale(2)} display="flex" flexBasis="100%" flexDirection="row" justifyContent="flex-end">
          <Button width={60} appearance="primary" onClick={saveClicked} disabled={isLoading || ticket.status === 'closed' || !parentEcommerceOrder}>{isLoading ? <Spinner size={18}/> : 'Save'}</Button>
        </Pane>
      </Pane>
      }
    </Pane>
  )
}

export default Zendesk;