import {observer} from 'mobx-react-lite';
import {Button, majorScale, Menu, Pane, Popover, Position} from 'evergreen-ui';
import Paginator from './Paginator';
import {useStore} from './useStore';

const OrderTableControls = observer(({bulkActions, bulkUpdateAction = false}) => {
  const {orderStore} = useStore();

  return (
    <Pane display="flex" flexDirection="row" flexShrink={0} flexBasis="100%" marginTop={majorScale(2)}>
      {bulkActions &&
        <Popover position={Position.BOTTOM_LEFT} content={
          <Menu>
            <Menu.Group title={`${orderStore.selectedOrders.length} selected`}>
              {bulkActions.map((a, i) => (
                <Menu.Item key={i} icon={a.icon} onSelect={a.action}>{a.label}</Menu.Item>
              ))}
            </Menu.Group>
          </Menu>
        }>
          <Button disabled={orderStore.selectedOrders.length === 0}>Bulk actions</Button>
        </Popover>
      }
      {bulkUpdateAction && <Button marginLeft={majorScale(1)} onClick={bulkUpdateAction}>Bulk update</Button>}
      <Paginator marginLeft="auto"/>
    </Pane>
  );
});

export default OrderTableControls;