import {observer} from 'mobx-react-lite';
import {
  Button,
  Heading,
  majorScale,
  Pane,
  Spinner,
  Table
} from 'evergreen-ui';
import {useParams} from 'react-router-dom';
import {useStore} from './useStore';
import {useEffect, useLayoutEffect, useState} from 'react';
import Filters from './Filters';

const Batch = observer(({orderType}) => {

  const {id} = useParams();
  const {batchStore, orderStore} = useStore();
  const [statusFilter, setStatusFilter] = useState(undefined);

  useLayoutEffect(() => {
    orderStore.setOrderType(orderType);
    batchStore.setOrderType(orderType);
  }, [orderStore, batchStore, orderType]);

  useEffect(() => {
    batchStore.setSelectedBatch(id);
    return () => {
      batchStore.setSelectedBatch(null);
    }
  }, [batchStore, id]);

  function BatchRow({order}) {
    const [isConfirming, setIsConfirming] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [action, setAction] = useState({newStatus: null});

    function onPrintCompleteClick(reference) {
      setIsConfirming(true);
      setAction({newStatus: 'Print Complete'});
    }

    function onPrintUnderwayClick(reference) {
      setIsConfirming(true);
      setAction({newStatus: 'Print Underway'});
    }

    function Actions() {
      switch (order.purchaseOrderStatus) {
        case 'Sent To Print':
          return <Button onClick={() => onPrintUnderwayClick(order.reference)}>Print underway</Button>
        case 'Print Underway':
          return <Button onClick={() => onPrintCompleteClick(order.reference)}>Print complete</Button>
        default:
          return <Pane/>
      }
    }

    function Confirmation() {
      function onCancel() {
        setIsConfirming(false);
        setAction({newStatus: null});
      }

      async function onConfirm() {
        setIsConfirming(false);
        setIsLoading(true);
        await orderStore.updateOrderStatus(order.reference, action.newStatus);
        order.purchaseOrderStatus = action.newStatus;
        setIsLoading(false);
        setAction({newStatus: null});
      }

      return (
        <Pane display="flex">
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onConfirm} appearance="primary" marginLeft={majorScale(1)}>Confirm</Button>
        </Pane>
      );
    }

    return (
      <Table.Row key={order.id}>
        <Table.TextCell>{order.reference}</Table.TextCell>
        <Table.TextCell>{order.purchaseOrderStatus}</Table.TextCell>
        <Table.Cell>
          {isLoading ? <Spinner size={24} /> : !isConfirming ? <Actions/> : <Confirmation/>}
        </Table.Cell>
      </Table.Row>
    );
  }

  function orderFilter(o) {
    return !statusFilter || statusFilter === o.purchaseOrderStatus;
  }

  return (
    <Pane display="flex" flexBasis="100%" flexWrap="wrap">
      {!batchStore.selectedBatch ?
        <Spinner marginTop={majorScale(2)} margin="auto"/> :
        <Pane display="flex" flexBasis="100%" flexWrap="wrap">
          <Heading size={800} flexBasis="100%" flexShrink={0}>Batch {batchStore.selectedBatch.name}</Heading>
          <Filters
            flexBasis="100%"
            title="Status"
            onFilterChanged={(e) => setStatusFilter(e)}
            activeFilter={statusFilter}
            filters={['All', 'Sent To Print', 'Print Underway', 'Print Complete']} />
          <Table flexGrow={1} marginTop={majorScale(1)}>
            <Table.Head paddingRight="0">
              <Table.TextHeaderCell>PO ref.</Table.TextHeaderCell>
              <Table.TextHeaderCell>PO status</Table.TextHeaderCell>
              <Table.TextHeaderCell>Update to</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {batchStore.selectedBatch.orders.filter(orderFilter).map(o => (
                <BatchRow order={o} key={o.reference} />
              ))}
            </Table.Body>
          </Table>
        </Pane>
      }
    </Pane>
  );
});

export default Batch;