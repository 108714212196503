import {majorScale, minorScale, Pane, Strong} from 'evergreen-ui';
import {NavLink} from 'react-router-dom';
import theme from './theme';

const SubNav = ({routes}) => {
  return (
    <Pane id="sub-nav" height={50}
          border="default" backgroundColor="#fafafa"
          paddingLeft={majorScale(12)}
          paddingRight={majorScale(12)}>
      <Pane display="flex" flexDirection="row" height="100%" alignItems="center" paddingBottom={minorScale(1)}>
        <Pane id="logo-spacer" flexBasis="120px" marginRight={majorScale(8)} />
        {routes.map((r, i) => (
          <Strong
            key={i}
            color="grey"
            marginTop={majorScale(1)}
            marginRight={majorScale(4)}
            display="flex"
            size={300}
            textTransform="uppercase">
            <NavLink exact={r.exact} to={r.path}
                     activeStyle={{
                       color: theme.colors.noissuePurple
                     }}>{r.displayName}
            </NavLink>
          </Strong>
        ))}
      </Pane>
    </Pane>
  );
}

export default SubNav;