import _ from "lodash";
import {Pane, Strong} from "evergreen-ui";
import React from "react";

const Cin7AsyncUpdateDataView = (params) => {
  const resource = _.get(params, 'data.eventPayload.resource', '');
  const updates = _.get(params, 'data.eventPayload.update', []).map(update => {
    return (
      <Pane flexBasis="max-content" marginRight={20}>
        <Strong>Order: </Strong> {update.id} / {update.stage}
      </Pane>
    )
  });

  return (
    <Pane display="flex" flexWrap="wrap">
      <Pane flexBasis="max-content" marginRight={20}><Strong>Resource: </Strong>{resource}</Pane>
      {updates}
    </Pane>
  )
};

export default Cin7AsyncUpdateDataView;