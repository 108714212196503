import {makeAutoObservable, runInAction} from 'mobx';
import {API} from 'aws-amplify';
import moment from 'moment';
import _ from 'lodash';
import retry from 'retry';

export default class DlqStore {
  state = '';
  error = null;
  pagination = {1: []};
  currentPageSize = 100;
  currentPage = 1;
  totalCount = 0;
  eventTypes = [];
  filterType = 'All';
  filterStartDate = moment().subtract(1, 'months').format(moment.HTML5_FMT.DATE);
  filterEndDate = moment().format(moment.HTML5_FMT.DATE);
  eventReports = {};

  constructor(rootStore) {
    makeAutoObservable(this, {}, {autoBind: true});
    this.rootStore = rootStore;
  }

  get dlqEvents() {
    return this.pagination[this.currentPage] || [];
  }

  get totalPages() {
    return Math.ceil(this.totalCount / this.currentPageSize);
  }

  async healthCheck() {
    const retryOperation = retry.operation({
      retries: 10,
      factor: 2
    });

    return new Promise((resolve, reject) => {
      retryOperation.attempt(async () => {
        API.get('noissue', `/admin/dlq/healthCheckDb`, {})
          .then(resolve)
          .catch(e => {
            if (retryOperation.retry(e)) {
              return;
            }
            reject(retryOperation.mainError());
          });
      });
    });
  }

  fetchEvents = _.debounce(async (force = false) => {
    if ('loading' === this.state) {
      return;
    }

    if ('done' === this.state && this.dlqEvents.length !== 0 && !force) {
      return;
    }

    this.error = null;
    this.pagination[this.currentPage] = [];
    this.state = 'loading';

    const queryStringParameters = {
      startDate: this.filterStartDate,
      endDate: this.filterEndDate,
      offset: (this.currentPage - 1) * this.currentPageSize,
      limit: this.currentPageSize
    };

    if (this.filterType !== 'All') {
      queryStringParameters.type = this.filterType;
    }

    await this.healthCheck();

    const [eventsResponse, eventTypes, reportsResponse] = await Promise.all([
      API.get('noissue', `/admin/dlq/events`, {queryStringParameters}),
      API.get('noissue', `/admin/dlq/eventTypes`, {}),
      API.get('noissue', `/admin/dlq/eventReports`, {queryStringParameters})
    ])
    runInAction(() => {
      this.eventTypes = eventTypes;
      this.eventReports = reportsResponse;
      this.pagination = {
        ...this.pagination,
        [this.currentPage]: eventsResponse.items || []
      };
      this.totalCount = eventsResponse.totalCount || 0;
      this.state = 'done';
    });
  }, 1000);

  loadPage(page) {
    this.currentPage = page;
    this.fetchEvents(true);
  }

  updateEventTypeFilter(type) {
    this.filterType = type;
    this.fetchEvents(true);
  }

  updateStartDateFilter(dateInStr) {
    this.filterStartDate = dateInStr;
    this.fetchEvents(true);
  }

  updateEndDateFilter(dateInStr) {
    this.filterEndDate = dateInStr;
    this.fetchEvents(true);
  }
}
