import {makeAutoObservable, runInAction} from 'mobx';
import {API} from 'aws-amplify';
import _ from 'lodash';
import * as Sentry from '@sentry/react';

export default class DlqDetailStore {
  state = '';
  error = null;
  workingEvent = {};
  isShowingDetailViewModel = false;
  isSubmittingEvent = false;

  constructor(rootStore) {
    makeAutoObservable(this, {}, {autoBind: true});
    this.rootStore = rootStore;
  }

  get eventPayload() {
    return this.workingEvent.eventPayload || {};
  }

  openDetailView(dlqEvent) {
    this.isShowingDetailViewModel = true;
    this.workingEvent = _.cloneDeep(dlqEvent);
  }

  closeDetailView() {
    this.isShowingDetailViewModel = false;
    this.workingEvent = {};
  }

  updateEventPayload(eventPayload) {
    this.workingEvent.eventPayload = eventPayload;
  }

  async replayEvent() {
    this.isSubmittingEvent = true;
    try {
      await API.post('noissue', `/admin/dlq/replay/${this.workingEvent.id}`, {
        body: this.workingEvent
      });

      runInAction(() => {
        this.isShowingDetailViewModel = false;
        this.workingEvent = {};
      });
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      this.isSubmittingEvent = false;
    }
  }
}
