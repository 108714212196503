import {Pane} from 'evergreen-ui';
import SubNav from './SubNav';
import {Switch, Route} from 'react-router-dom';
import RouterConentPane from './RouterConentPane';
import Orders from "./Orders";
import Batches from "./Batches";
import {Redirect} from "react-router";
import Batch from "./Batch";
import PurchaseOrder from "./PurchaseOrder";

const routes = [{
  displayName: 'Orders',
  path: '/manufacturing/orders',
  exact: false
}, {
  displayName: 'Batches',
  path: '/manufacturing/batches'
}]

const Manufacturing = () => {
  return (
    <Pane>
      <SubNav routes={routes}/>
      <RouterConentPane>
        <Switch>
          <Route exact path="/manufacturing">
            <Redirect to="/manufacturing/orders" />
          </Route>
          <Route exact path="/manufacturing/orders">
            <Orders />
          </Route>
          <Route exact path="/manufacturing/batches">
            <Batches orderType="PO" />
          </Route>
          <Route exact path="/manufacturing/batches/:id">
            <Batch orderType="PO" />
          </Route>
          <Route exact path="/manufacturing/orders/:reference">
            <PurchaseOrder />
          </Route>
        </Switch>
      </RouterConentPane>
    </Pane>
  );
}

export default Manufacturing;