import {Dialog, FilePicker, Label, majorScale, Textarea} from 'evergreen-ui';
import {useState} from 'react';
import {useStore} from './useStore';

const FeedbackDialog = ({isShown, onCloseComplete, orderReference}) => {
  const {orderStore} = useStore();
  const [feedbackComment, setFeedbackComment] = useState(null);
  const [feedbackFiles, setFeedbackFiles] = useState(null);
  const [isCommentSending, setIsCommentSending] = useState(false);

  function onFeedbackCloseComplete() {
    setFeedbackComment(undefined);
    setFeedbackFiles(undefined);
    onCloseComplete();
  }

  async function onSendFeedbackConfirm() {
    setIsCommentSending(true);
    await orderStore.putComment(orderReference, feedbackComment, feedbackFiles);
    setIsCommentSending(false);
    onCloseComplete();
  }

  return (
    <Dialog isShown={isShown}
            title={`Send feedback for ${orderReference}`}
            onConfirm={onSendFeedbackConfirm}
            onCloseComplete={onFeedbackCloseComplete}
            isConfirmLoading={isCommentSending}
            hasClose={!isCommentSending}
            shouldCloseOnEscapePress={!isCommentSending}
            shouldCloseOnOverlayClick={false}
            isConfirmDisabled={!feedbackComment || !feedbackComment.trim()}
            hasCancel={!isCommentSending}
    >
      <Label display="block" htmlFor="feedbackText">Feedback</Label>
      <Textarea
        id="feedbackText"
        placeholder="Describe your issue"
        value={feedbackComment}
        onChange={e => setFeedbackComment(e.target.value)} />
      <Label display="block" htmlFor="feedbackFilePicker" marginTop={majorScale(1)}>Attachments</Label>
      <FilePicker
        id="feedbackFilePicker"
        multiple
        disabled={isCommentSending}
        width={250}
        onChange={setFeedbackFiles}
        placeholder="None"
      />
    </Dialog>
  );
}

export default FeedbackDialog;