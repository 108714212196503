import {makeAutoObservable, runInAction} from 'mobx';
import {API} from 'aws-amplify';
import * as Sentry from '@sentry/react';

export default class BatchStore {
  _orderType = 'PO';
  _query = {
    page: 1,
    pageSize: 100
  };
  _batches = {PO: [], SO: []};
  _state = null;
  _error = null;
  _selectedBatch = null;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  get batches() {
    return this._batches[this._orderType];
  }

  setOrderType(type) {
    if (!['PO', 'SO'].includes(type)) {
      return;
    }
    this._orderType = type;
  }

  get isLoading() {
    const loading = !this._state || this._state === 'pending';
    return loading;
  }

  get selectedBatch() {
    return this._selectedBatch;
  }

  deleteBatch(id) {
    this._batches[this._orderType] = this.batches.filter(b => b.id !== id);
  }

  async createBatch({id, orderRefs}) {
    if (!id || !orderRefs || orderRefs.length < 1) {
      return;
    }

    if (this.batches.find(b => b.id === id)) {
      return;
    }

    const tempId = Math.random().toString(36).substring(7);
    const newBatch = {id: tempId, name: id, orderRefs, status: 'Processing', loading: true, batchSize: orderRefs.length}
    this._batches[this._orderType].unshift(newBatch);

    const response = await API.post('noissue', '/batches', {
      queryStringParameters: {
        type: this._orderType
      },
      body: {
        name: id,
        references: orderRefs
      }
    });

    runInAction(() => {
      const batch = this.batches.find(b => b.id === tempId);
      batch.id = response.id;
      batch.createdDate = new Date(response.createdDate);
      batch.name = response.name;
      batch.batchSize = response.batchSize;
      batch.viewUrl = response.viewUrl;
      batch.exportUrl = response.exportUrl;
      batch.status = 'Ready';
      batch.loading = false;
    });
  }

  async listBatches(force = false) {
    if (this._state === 'pending') {
      return;
    }

    if (this._state === 'done' && this.batches.length !== 0 && !force) {
      return;
    }

    this._error = null;
    this._batches[this._orderType] = [];
    this._state = 'pending';

    try {
      const batchesResponse = await API.get('noissue', '/batches', {
        queryStringParameters: {
          limit: this._query.pageSize,
          type: this._orderType
        }
      });
      runInAction(() => {
        this._batches[this._orderType] = batchesResponse.map(b => {
          b.status = b.viewUrl || b.exportUrl ? 'Ready' : 'Processing';
          b.loading = b.status === 'Processing';
          return b;
        });
        this._state = 'done';
      });
    } catch (e) {
      Sentry.captureException(e);
      runInAction(() => {
        this._state = 'error';
        this._error = e;
      });
    }
  }

  setSelectedBatch(id) {
    if (this._selectedBatch && this._selectedBatch.id === id) {
      return;
    }

    // allow deselecting
    if (!id) {
      this._selectedBatch = id;
      return;
    }

    this.fetchBatch(id);
  }

  async fetchBatch(id, force = false) {
    if (this._state === 'pending') {
      return;
    }

    if (this._state === 'done' && this._selectedBatch && this._selectedBatch.id === id && !force) {
      return;
    }

    this._error = null;
    this._selectedBatch = null;
    this._state = 'pending';

    try {
      const batch = await API.get('noissue', `/batches/${id}`, {
        queryStringParameters: {
          type: this._orderType
        }
      });
      runInAction(() => {
        this._selectedBatch = batch;
        this._state = 'done';
      });
    } catch (e) {
      Sentry.captureException(e);
      runInAction(() => {
        this._state = 'error';
        this._error = e;
      });
    }
  }
}