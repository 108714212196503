import {makeAutoObservable, runInAction} from 'mobx';
import {API, Auth} from 'aws-amplify';
import awsConfig from '../aws-config.json';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import _ from 'lodash';

export default class OrderStore {
  _orderType = 'PO';
  _orders = {PO: [], SO: []};
  _displayedRowCount = {PO: 0, SO: 0};  //local filtering
  _selectedOrders = {PO: [], SO: []};
  state = null;
  error = null;
  _query = {
    page: 1,
    pageSize: 100,
    status: '',
    q: '',
    inBatch: false
  };
  currentOrderRef = null;
  currentOrderLoading = false;

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
  }

  get orders() {
    return this._orders[this._orderType].filter(this.orderFilter);
  }

  get isLoading() {
    return this.state === 'pending';
  }

  get isCurrentOrderLoading() {
    return this.currentOrderLoading;
  }

  get currentOrder() {
    return this.orders.find((o) => o.reference === this.currentOrderRef);
  }

  get query() {
    return this._query;
  }

  get queryString() {
    const params = new URLSearchParams();

    Object.entries(this.query).forEach((q) => {
      if (q[1] !== undefined && q[1] !== null) {
        params.append(...q);
      }
    });

    return params.toString();
  }

  get displayedRowCount() {
    return this._displayedRowCount[this._orderType];
  }

  get selectedOrders() {
    return this._selectedOrders[this._orderType];
  }

  set displayedRowCount(value) {
    this._displayedRowCount[this._orderType] = value;
  }

  set selectedOrders(value) {
    this._selectedOrders[this._orderType] = value;
  }

  async setQueryParam(key, value) {
    if (this._query[key] !== value) {
      this._query[key] = value;
      await this.fetchOrders(true);
    }
  }

  set query(value) {
    if (value.page && this._query.page !== parseInt(value.page)) {
      this._query.page = parseInt(value.page);
    }

    if (value.pageSize && this._query.pageSize !== parseInt(value.pageSize)) {
      this._query.pageSize = parseInt(value.pageSize);
    }

    if (this._query.status !== value.status) {
      this._query.status = value.status;
    }

    if (value.q && this._query.q !== value.q) {
      this._query.q = value.q;
    }

    if (this._query.category !== value.category) {
      this._query.category = value.category;
    }
  }

  orderFilter(o) {
    const statusField = this._orderType === 'PO' ? 'purchaseOrderStatus' : 'salesOrderStatus'
    let pass = true;

    if (this.query.q) {
      pass = (o.reference && o.reference.toLowerCase().includes(this.query.q.toLowerCase())) ||
        (o.salesOrderReference && o.salesOrderReference.toLowerCase().includes(this.query.q.toLowerCase()));
      if (!pass) {
        return false;
      }
    }

    if (this.query.status) {
      pass = this.query.status === o[statusField];
    }

    if (this.query.inBatch === false) {
      pass = !o.isInBatch;
    }

    if (this._orderType === 'PO' && this.query.category) {
      pass = this.query.category === o.category;
    }

    return pass;
  }

  async setCurrentOrderRef(reference) {
    this.currentOrderRef = reference;
    try {
      this.currentOrderLoading = true;
      const orderDetail = await API.get('noissue', `/orders/${reference}`, {});
      const indexToUpdate = this.orders.findIndex((o) => o.reference === reference);
      if (indexToUpdate >= 0) {
        runInAction(() => {
          this.orders[indexToUpdate] = {
            ...this.orders[indexToUpdate],
            ...orderDetail
          };
          this.currentOrderLoading = false;
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      this.error = e;
      this.currentOrderLoading = false;
    }
  }

  setOrderType(type) {
    if (!['PO', 'SO'].includes(type)) {
      return;
    }
    this._orderType = type;
  }

  setPageSize(size) {
    this._query = {
      ...this._query,
      pageSize: size
    };
    this.fetchOrders(true);
  }

  async nextPage() {
    this._query.page++;
    this.fetchOrders(true);
  }

  previousPage() {
    if (this._query.page === 1) {
      return;
    }
    this._query.page--;
    this.fetchOrders(true);
  }

  async updateOrderStatus(reference, newStatus) {
    try {
      await API.put('noissue', `/orders/${reference}/stage`, {
        body: {
          stage: newStatus
        }
      })
    } catch (e) {
      Sentry.captureException(e);
      this.error = e;
      this.state = 'error';
    }
  }

  enrichTagInfoForPO(order) {
    const tags = [];
    if (order.slaDays < 0) tags.push('overdue');
    if (order.reorder) tags.push('reorder');
    if (order.reprint) tags.push('reprint');
    if (order.isInBatch) tags.push('in batch');
    order.tags = tags.join(' ');
  }

  enrichTagInfoForSO(order) {
    const tags = [];
    if (order.slaDays < 0) tags.push('overdue');
    if (order.isInBatch) tags.push('in batch');
    if (order.logisticsProvider) tags.push(`Sent to ${order.logisticsProvider}`);
    order.tags = tags.join(' ');
  }

  enrichTagInfo(orders) {
    orders.forEach(order => {
      if (order.type === 'SO') {
        this.enrichTagInfoForSO(order);
      }
      if (order.type === 'PO') {
        this.enrichTagInfoForPO(order);
      }
    });
  }

  async fetchOrders(force) {
    if (this.state === 'pending') {
      return;
    }

    if (this.state === 'done' && this.orders.length !== 0 && !force) {
      return;
    }

    this._orders[this._orderType] = [];
    this._selectedOrders[this._orderType] = [];
    this._displayedRowCount[this._orderType] = 0;
    this.error = null;
    this.state = 'pending';

    try {
      const queryStringParameters = {
        limit: this.query.pageSize,
        type: this._orderType
      };

      if (this.query.q) {
        queryStringParameters.q = this.query.q;
      }

      if (this.query.status) {
        queryStringParameters.status = this.query.status;
      }

      if (this.query.category) {
        queryStringParameters.category = this.query.category;
      }

      if (typeof this.query.inBatch === 'boolean') {
        queryStringParameters.inBatch = this.query.inBatch;
      }

      let offset = null;
      let orders = [];
      do {
        if (offset) {
          queryStringParameters.offset = offset;
        }
        queryStringParameters.limit = this.query.pageSize - orders.length;
        const {items, nextOffset} = await API.get('noissue', '/orders', {
          queryStringParameters
        });
        offset = nextOffset;
        orders = orders.concat(items);
      } while (orders.length < this.query.pageSize && !!offset)

      orders = _.orderBy(orders, ['slaDays', 'reference'], ['asc', 'asc']);
      orders = _.take(orders, this.query.pageSize);

      this.enrichTagInfo(orders);

      runInAction(() => {
        this._orders[this._orderType] = orders;
        this._displayedRowCount[this._orderType] = orders.length;
        this.state = 'done';
      });
    } catch (e) {
      Sentry.captureException(e);
      runInAction(() => {
        this.state = 'error';
        this.error = e;
      });
    }
  }

  async putComment(reference, comment, files = []) {
    try {
      let uploadToken;
      if (files && files.length) {
        const initiateResponse = await API.post('noissue', `/orders/${reference}/attachments`, {});
        const uploadUrl = initiateResponse.uploadUrl;

        for (const file of files) {
          const response = await fetch(`${uploadUrl}&filename=${file.name}`, {
            method: 'POST',
            body: file
          });
          const body = await response.json();
          uploadToken = body.upload.token;
        }
      }

      return API.put('noissue', `/orders/${reference}/comments`, {
        body: {
          comment,
          uploads: [uploadToken]
        },
        response: true
      });
    } catch (e) {
      Sentry.captureException(e);
      runInAction(() => {
        this.state = 'error';
        this.error = e;
      });
    }
  }

  async bulkUpdate(file, type) {
    try {
      await this.fileUpload('/orders', file, {type});
      runInAction(() => {
        this.state = 'done';
        this.fetchOrders(true).catch(e => e);
      });
    } catch (e) {
      Sentry.captureException(e);
      runInAction(() => {
        this.state = 'error';
        this.error = e;
      });
    }
  }

  async fileUpload(uploadPath, file, params) {
    const formData = new FormData();
    formData.append('file', file);
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    // awsconfig here is the configuration you setup to use amplify already with your app
    const url = awsConfig.apiEndpoint + uploadPath;
    return await axios.put(url, formData, {
      headers: {
        'Content-Type': file.type,
        Authorization: `Bearer ${token}`,
      },
      params
    });
  };

  async sendOrdersToCbip() {
    const readyToShipOrders = _.filter(this.selectedOrders, (order => order.salesOrderStatus === 'Ready To Ship'));
    await API.post('noissue', '/integration/cbip/orders', {
      body: readyToShipOrders
    });
    await this.fetchOrders(true);
  }
}