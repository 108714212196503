import ReactECharts from 'echarts-for-react';
import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../useStore';
import _ from 'lodash';

const ReportChart = observer(() => {
  const {dlqStore} = useStore();

  const chartCategories = _.get(dlqStore, 'eventReports.dateRange', []);
  const reportResults = _.get(dlqStore, 'eventReports.reportResults', []);
  const chartSeries = _.map(reportResults, (results, type) => {
    return {
      name: type,
      type: 'bar',
      stack: 'total',
      label: {
        show: false
      },
      emphasis: {
        focus: 'series'
      },
      data: chartCategories.map(date => results[date] || 0)
    };
  });

  const chartOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
      }
    },
    legend: {},
    grid: {
      left: '1%',
      right: '1%',
      bottom: '1%',
      containLabel: true
    },
    yAxis: {
      type: 'value'
    },
    xAxis: {
      type: 'category',
      data: chartCategories
    },
    series: chartSeries
  };

  return (
    <ReactECharts option={chartOptions} notMerge={true} style={{height: '250px', width: '100%'}}/>
  )
});

export default ReportChart;