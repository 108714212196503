import _ from "lodash";
import React from "react";
import {Pane, Strong} from 'evergreen-ui';

const stockedSalesOrderCreationCompleteDataView = (params) => {
  const reference = _.get(params, 'data.eventPayload.reference', '');
  const branchID = _.get(params, 'data.eventPayload.branchID', '');
  const deliveryCountry = _.get(params, 'data.eventPayload.deliveryCountry', '');
  const estimatedDeliveryDate = _.get(params, 'data.eventPayload.estimatedDeliveryDate', '');
  return (
    <Pane display="flex" flexWrap="wrap">
      <Pane flexBasis="max-content" marginRight={20}><Strong>Reference: </Strong>{reference}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>BranchID: </Strong>{branchID}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Delivery Country: </Strong>{deliveryCountry}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Estimated Delivery Date: </Strong>{estimatedDeliveryDate}</Pane>
    </Pane>
  )
}

export default stockedSalesOrderCreationCompleteDataView;