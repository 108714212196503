import {observer} from 'mobx-react-lite';
import {autorun, runInAction} from 'mobx';
import {useEffect, useLayoutEffect, useState} from 'react';
import {useStore} from './useStore';
import {
  Dialog,
  ExportIcon,
  FilePicker,
  FlowBranchIcon,
  Heading,
  majorScale,
  Pane,
  SearchInput,
  Text,
  UnorderedList,
  ListItem,
  Strong,
  toaster
} from 'evergreen-ui';
import {useLocation} from 'react-router';
import {useHistory} from 'react-router-dom';
import OrdersTableControls from './OrdersTableControls';
import SalesOrdersTable from './SalesOrdersTable';
import BatchDialog from './BatchDialog';
import moment from "moment";
import Filters from './Filters';
import debounce from 'lodash/debounce';
import CsvPreviewPane from "./CsvPreviewPane";
import _ from "lodash";
import {Auth} from "aws-amplify";

const SalesOrders = observer(() => {
  const location = useLocation();
  const history = useHistory();
  const {orderStore, batchStore} = useStore();
  const [batchName, setBatchName] = useState(null);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [batchCreating, setBatchCreating] = useState(false);
  const [batchUpdating, setBatchUpdating] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [file, setFile] = useState(null);
  const [isValidForUpload, setValidForUpload] = useState(false);
  const [showCbipModal, setShowCbipModal] = useState(false);
  const [isSendingToCbip, setSendingToCbip] = useState(false);
  const [ordersToSendToCbip, setOrdersToSendToCbip] = useState([]);
  const [ordersCannotSendToCbip, setOrdersCannotSendToCbip] = useState([]);
  const [bulkActions, setBulkActions] = useState([{
    label: 'CSV export...',
    action: csvExportOnSelect,
    icon: <ExportIcon/>
  }]);

  runInAction(() => {
    orderStore.query = Object.fromEntries(new URLSearchParams(location.search));
  });

  useLayoutEffect(() => {
    orderStore.setOrderType('SO');
    batchStore.setOrderType('SO');
    orderStore.fetchOrders();
  }, [orderStore, batchStore]);

  useEffect(() => {
    function onSelectedSendToCbip() {
      const [readyToShipOrders, otherOrders] = _.partition(orderStore.selectedOrders, order => order.salesOrderStatus === 'Ready To Ship');
      if (orderStore.selectedOrders.length > 0) {
        setShowCbipModal(true);
        setOrdersToSendToCbip(readyToShipOrders);
        setOrdersCannotSendToCbip(otherOrders);
      }
    }
    Auth.currentAuthenticatedUser().then(user => {
      const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
      const permittedGroup = groups.find(g => g === 'cbip:send');
      if (!!permittedGroup) {
        setBulkActions([{
          label: 'CSV export...',
          action: csvExportOnSelect,
          icon: <ExportIcon/>
        }, {
          label: 'Send to CBIP...',
          action: onSelectedSendToCbip,
          icon: <FlowBranchIcon color="success"/>
        }
        ]);
      }
    });
  }, [setBulkActions, orderStore])

  autorun(() => {
    if (orderStore.queryString !== location.search.substring(1)) {
      history.push({search: orderStore.queryString});
    }
  });

  function csvExportOnSelect() {
    const defaultBatchName = moment().format('YYYY-MM-DD-HH:mm') ;
    setBatchName(defaultBatchName);
    setShowDownloadModal(true);
  }

  async function createBatch() {
    setBatchCreating(true);
    const orderRefs = orderStore.selectedOrders.map(o => o.reference);
    await batchStore.createBatch({
      id: batchName,
      orderRefs
    });
    setBatchCreating(false);
    setShowDownloadModal(false);
    history.push('/fulfilment/batches');
  }

  async function onConfirmedSendToCbip() {
    setSendingToCbip(true);
    try {
      await orderStore.sendOrdersToCbip();
      setShowCbipModal(false);
    } catch (e) {
      console.log(e);
    } finally {
      setSendingToCbip(false);
    }
  }

  function onStatusFilterChanged(e) {
    orderStore.setQueryParam('status', e === 'All' ? '' : e);
  }

  function onFilterChanged(e) {
    orderStore.setQueryParam('inBatch', e === 'Not in batch' ? false : null);
  }

  function onSearchChanged(query) {
    orderStore.setQueryParam('q', query);
  }

  function onFileChange(files) {
    setFile(files[0]);
  }

  async function bulkUpdateAction() {
    setShowUpdateModal(true);
  }

  async function onUploadConfirm(close) {
    try {
      setBatchUpdating(true);
      await orderStore.bulkUpdate(file, 'SO');
    } finally {
      if (!orderStore.error) {
        close();
      } else {
        toaster.danger('Error!', {
          description: orderStore.error.response.data.message || orderStore.error.message
        });
      }
      setBatchUpdating(false);
    }
  }

  return (
    <Pane display="flex" flexBasis="100%" flexWrap="wrap">
      <Heading flexShrink={0} flexBasis="100%" size={800}>Sales Orders</Heading>
      <Pane display="flex" flexBasis="100%" flexDirection="row">
        <Filters title="Filters"
                 filters={['None', 'Not in batch']}
                 onFilterChanged={onFilterChanged}
                 activeFilter={orderStore.query.inBatch === false ? 'Not in batch' : 'None'}
        />
      </Pane>
      <Pane display="flex" flexBasis="100%" flexDirection="row" justifyContent="space-between">
        <Filters onFilterChanged={onStatusFilterChanged}
                 title="Status"
                 activeFilter={orderStore.query.status}
                 filters={['All', 'Awaiting Print Completion', 'Ready To Ship', 'Order Shipped', 'Delivered']}/>
        <SearchInput display="flex"
                     alignSelf="flex-end"
                     placeholder="search orders"
                     onChange={debounce(e => onSearchChanged(e.target.value), 300)}
                     defaultValue={orderStore.query.q ? orderStore.query.q : ''}/>
      </Pane>
      <OrdersTableControls bulkActions={bulkActions} bulkUpdateAction={bulkUpdateAction}/>
      <SalesOrdersTable/>
      <BatchDialog
        batchName={batchName}
        setBatchName={setBatchName}
        title={`Create batch (${orderStore.selectedOrders.length} selected)`}
        isShown={showDownloadModal}
        onCloseComplete={() => setShowDownloadModal(false)}
        isCreating={batchCreating}
        onConfirm={createBatch}
      />
      <Dialog isShown={showUpdateModal}
              title="Bulk update"
              confirmLabel="Upload"
              isConfirmLoading={batchUpdating}
              isConfirmDisabled={!isValidForUpload}
              hasClose={!batchUpdating}
              shouldCloseOnOverlayClick={!batchUpdating}
              shouldCloseOnEscapePress={!batchUpdating}
              onConfirm={onUploadConfirm}
              onCloseComplete={() => setShowUpdateModal(false)}
              width="80%">
        <Text size={300}>Select a CSV file to bulk update orders.</Text>
        <FilePicker marginTop={majorScale(1)}
                    disabled={batchUpdating}
                    onChange={onFileChange}
                    width={400}
                    accept=".csv,text/csv"
                    placeholder="select CSV file"/>
        <CsvPreviewPane file={file} type="SO" setValidForUploadFn={setValidForUpload}/>
      </Dialog>

      <Dialog isShown={showCbipModal}
              title="Send to CBIP"
              confirmLabel="Send"
              isConfirmLoading={isSendingToCbip}
              isConfirmDisabled={ordersToSendToCbip.length === 0}
              hasClose={!isSendingToCbip}
              shouldCloseOnOverlayClick={!isSendingToCbip}
              shouldCloseOnEscapePress={!isSendingToCbip}
              onConfirm={onConfirmedSendToCbip}
              onCloseComplete={() => setShowCbipModal(false)}>

        {ordersToSendToCbip.length > 0 ?
          <>
            <Text size={300}>
              Are you sure about sending
              {ordersToSendToCbip.length > 1 ? ` the following orders ` : ' the following order '}
              to CBIP?
            </Text>
            <UnorderedList>
              {ordersToSendToCbip.map((order) => {
                return (<ListItem key={order.reference}><Strong>{order.reference}</Strong> ({order.salesOrderStatus})</ListItem>);
              })}
            </UnorderedList>
          </>
          : ''
        }
        {ordersCannotSendToCbip.length > 0 ?
          <>
            <Text size={300}>
              {ordersCannotSendToCbip.length > 1 ? ` The following orders ` : ' The following order '}
              cannot send to CBIP:
            </Text>
            <UnorderedList>
              {ordersCannotSendToCbip.map((order) => {
                return (<ListItem key={order.reference}><Text color="muted">{order.reference} ({order.salesOrderStatus})</Text></ListItem>);
              })}
            </UnorderedList>
          </>
          : ''
        }
      </Dialog>
    </Pane>
  );
});

export default SalesOrders;