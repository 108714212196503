import {observer} from "mobx-react-lite";
import {useStore} from "../../useStore";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {Combobox, majorScale, Pagination, Pane, Strong, TextInput} from "evergreen-ui";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import DataViewRenderer from "./dataViews";
import ActionButtonsRenderer from "./ActionButtonsRederer";
import theme from "../../theme";

const DlqTable = observer(() => {
  const {dlqStore} = useStore();
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    if (!gridApi) {
      return;
    }
    if (dlqStore.state === 'loading') {
      gridApi.showLoadingOverlay();
    } else {
      gridApi.hideOverlay();
    }
  }, [dlqStore.state, gridApi]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const defaultColDef = {
    // make every column use 'text' filter by default
    // filter: 'agTextColumnFilter',
    // floatingFilter: true,
    // suppressMenu: true,
    // sortable: true,
    // unSortIcon: true,
    resizable: true
  };

  const LoadingOverlay = () => {
    return (
      <span className="ag-overlay-loading-center">Please wait while data are loading</span>
    );
  };

  const timeFormatter = (params) => {
    return !!params.value ? moment(params.value).format('YYYY-MM-DD HH:mm:ss') : '';
  };

  const onPageChange = (page) => {
    dlqStore.loadPage(page);
  };

  const onTypeFilterChanged = (selected) => {
    dlqStore.updateEventTypeFilter(selected);
  };

  const onStartDateChanged = (e) => {
    dlqStore.updateStartDateFilter(e.target.value);
  };

  const onEndDateChanged = (e) => {
    dlqStore.updateEndDateFilter(e.target.value);
  };

  return (
    <Pane flexBasis="100%" marginTop={majorScale(2)}>
      <Pane display="flex" alignSelf="flex-end" flexBasis="max-content" alignItems="center">
        <Strong color={theme.colors.noissuePurple} marginRight={majorScale(1)}>Event Type:</Strong>
        <Pane width={350}>
          <Combobox
            onChange={onTypeFilterChanged}
            items={['All', ...dlqStore.eventTypes]}
            width={350}
            initialSelectedItem={dlqStore.filterType ? dlqStore.filterType : 'All'}/>
        </Pane>
        <Pane marginLeft="auto">
          <Strong color={theme.colors.noissuePurple} marginRight={majorScale(1)}>From:</Strong>
          <TextInput type="date"
                     width={150}
                     onChange={onStartDateChanged}
                     value={dlqStore.filterStartDate}
                     max={dlqStore.filterEndDate}/>
          <Strong color={theme.colors.noissuePurple} marginRight={majorScale(1)} marginLeft={majorScale(3)}>To:</Strong>
          <TextInput type="date"
                     width={150}
                     onChange={onEndDateChanged}
                     value={dlqStore.filterEndDate}
                     min={dlqStore.filterStartDate}
                     max={moment().format(moment.HTML5_FMT.DATE)}/>
        </Pane>
      </Pane>
      <Pane height='60vh' marginTop={majorScale(1)} className="ag-theme-balham">
      <AgGridReact
        reactUi={true}
        rowHeight={80}
        defaultColDef={defaultColDef}
        rowData={dlqStore.dlqEvents}
        onGridReady={onGridReady}
        suppressRowClickSelection={true}
        enableCellTextSelection={true}
        rowSelection={'multiple'}
        frameworkComponents={{
          dataViewRenderer: DataViewRenderer,
          actionButtonsRenderer: ActionButtonsRenderer,
          loadingOverlay: LoadingOverlay
        }}
        loadingOverlayComponent={'loadingOverlay'}>
        <AgGridColumn field="detailType"
                      headerName="Type"
                      width={250}/>
        <AgGridColumn headerName="Data"
                      width={700}
                      cellRenderer="dataViewRenderer"/>
        <AgGridColumn field="time"
                      headerName="Time"
                      width={160}
                      valueFormatter={timeFormatter}/>
        <AgGridColumn field="dlqReplayCount"
                      headerName="Replay Count"
                      width={120}/>
        <AgGridColumn headerName=""
                      width={160}
                      cellRenderer="actionButtonsRenderer"/>
      </AgGridReact>
      </Pane>
      <Pane display="flex">
        <Pagination marginLeft="auto" page={dlqStore.currentPage} totalPages={dlqStore.totalPages} onPageChange={onPageChange} />
      </Pane>
    </Pane>
  );
});

export default DlqTable;