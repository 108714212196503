import {majorScale, Strong, Tab, Tablist} from 'evergreen-ui';
import theme from './theme';

const Filters = ({filters, activeFilter, onFilterChanged, title, ...props}) => {
    function onSelect(index) {
      index === 0 ? onFilterChanged(undefined) : onFilterChanged(filters[index]);
    }

    return (
      <Tablist display="flex" marginTop={majorScale(2)} {...props} alignItems="center">
        <Strong marginRight={majorScale(1)} color={theme.colors.noissuePurple}>{title}</Strong>
        {filters.map((tab, index) => (
          <Tab key={index}
               onSelect={() => onSelect(index)}
               isSelected={(!activeFilter && index === 0) || tab === activeFilter}>
            {tab}
          </Tab>
        ))}
      </Tablist>
    );
}

export default  Filters;