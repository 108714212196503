import {majorScale, Pane} from "evergreen-ui";

const RouterContentPane = (props) => {
  return (
    <Pane marginTop={majorScale(6)}
          marginBottom={majorScale(8)}
          marginLeft="auto"
          marginRight="auto"
          width={1400}
          display="flex"
          alignItems="center"
          flexWrap="wrap">
      {props.children}
    </Pane>
  );
}

export default RouterContentPane;