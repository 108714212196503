import {observer} from 'mobx-react-lite';
import {
  Pane,
  Heading,
  Table,
  majorScale,
  DownloadIcon,
  Tooltip,
  Link,
  Spinner,
  FolderOpenIcon, DocumentOpenIcon
} from 'evergreen-ui';
import {useStore} from './useStore';
import {useLayoutEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {generatePath} from 'react-router';

const Batches = observer(({orderType = 'PO'}) => {
  const basePath = orderType === 'PO' ? '/manufacturing' : '/fulfilment';
  const {batchStore} = useStore();
  const history = useHistory();

  useLayoutEffect(() => {
    batchStore.setOrderType(orderType);
    batchStore.listBatches();
  }, [batchStore, orderType]);

  function viewBatch(e, id) {
    history.push(generatePath(`${basePath}/batches/:id`, {id}));
  }

  return (
    <Pane display="flex" flexBasis="100%" flexWrap="wrap">
      <Heading size={800} flexBasis="100%" flexShrink={0}>Batches</Heading>
      <Table flexGrow={1} marginTop={majorScale(1)}>
        <Table.Head paddingRight="0">
          <Table.TextHeaderCell>Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>Item count</Table.TextHeaderCell>
          <Table.TextHeaderCell>Status</Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={90} flexGrow={0} />
        </Table.Head>
        {batchStore.isLoading ? <Spinner marginTop={majorScale(2)} marginX="auto"/> :
          <Table.Body>
            {batchStore.batches.map(b => (
              <Table.Row key={b.id}>
                <Table.TextCell>{b.name}</Table.TextCell>
                <Table.TextCell>{b.batchSize}</Table.TextCell>
                <Table.TextCell>{b.status}</Table.TextCell>
                <Table.Cell flexBasis={90} flexGrow={0} justifyContent="right">
                  {b.loading ? <Spinner size={24}/> :
                    <Pane display="flex" justifyContent="flex-end" flexDirection="row" width="100%">
                      <Tooltip content="View batch" marginLeft={majorScale(1)}>
                        <Link lineHeight="normal" onClick={(e) => {
                          e.preventDefault();
                          viewBatch(e, b.id);
                        }} href={generatePath(`${basePath}/batches/:id`, {id: b.id})}><FolderOpenIcon/></Link>
                      </Tooltip>
                      {b.viewUrl && <Tooltip content="View files">
                        <Link marginLeft={majorScale(1)} lineHeight="normal" href={b.viewUrl} target="_blank"><DocumentOpenIcon/></Link>
                      </Tooltip>}
                      {b.exportUrl && <Tooltip content="CSV download">
                        <Link marginLeft={majorScale(1)} lineHeight="normal" href={b.exportUrl} target="_blank"><DownloadIcon/></Link>
                      </Tooltip>}
                    </Pane>
                  }
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        }
      </Table>
    </Pane>
  );
});

export default Batches;