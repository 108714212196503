import SubNav from './SubNav';
import RouterContentPane from './RouterConentPane';
import {Route, Switch} from 'react-router-dom';
import {Redirect} from 'react-router';
import Batches from './Batches';
import {Heading, Pane} from 'evergreen-ui';
import SalesOrders from './SalesOrders';
import SalesOrderBatch from './SalesOrderBatch';

const routes = [{
  displayName: 'Orders',
  path: '/fulfilment/orders',
  exact: false
}, {
  displayName: 'Batches',
  path: '/fulfilment/batches'
}]

const Fulfilment = () => {
  return (
    <Pane>
      <SubNav routes={routes}/>
      <RouterContentPane>
        <Switch>
          <Route exact path="/fulfilment">
            <Redirect to="/fulfilment/orders" />
          </Route>
          <Route exact path="/fulfilment/orders">
            <SalesOrders />
          </Route>
          <Route exact path="/fulfilment/batches">
            <Batches orderType="SO" />
          </Route>
          <Route exact path="/fulfilment/batches/:id">
            <SalesOrderBatch orderType="SO" />
          </Route>
          <Route exact path="/fulfilment/sales-order/:reference">
            <Heading size={800}>Sales Order</Heading>
          </Route>
        </Switch>
      </RouterContentPane>
    </Pane>
  );
}

export default Fulfilment;