import {Button, CogIcon} from 'evergreen-ui';
import React from 'react';
import {useStore} from '../../useStore';

const ActionButtonsRenderer = (params) => {
  const {dlqDetailStore} = useStore();
  const onClick = () => {
    dlqDetailStore.openDetailView(params.data);
  };

  return (
    <Button marginY={8} marginRight={12} iconBefore={CogIcon} onClick={onClick}>
      View / Replay
    </Button>
  )
};

export default ActionButtonsRenderer;