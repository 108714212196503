import _ from "lodash";
import {Pane, Strong} from "evergreen-ui";
import React from "react";

const OrderCreationCompleteDataView = (params) => {
  const orderId = _.get(params, 'data.eventPayload.ecommerceOrder.order', '');
  const createdDate = _.get(params, 'data.eventPayload.ecommerceOrder.createdDate', '');
  const customerEmail = _.get(params, 'data.eventPayload.ecommerceOrder.customerEmail', '');
  const deliveryCountry = _.get(params, 'data.eventPayload.ecommerceOrder.deliveryCountry', '');

  return (
    <Pane display="flex" flexWrap="wrap">
      <Pane flexBasis="max-content" marginRight={20}><Strong>Order ID: </Strong>{orderId}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Created Date: </Strong>{createdDate}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Customer Email: </Strong>{customerEmail}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Delivery Country: </Strong>{deliveryCountry}</Pane>
    </Pane>
  )
};

export default OrderCreationCompleteDataView;