import _ from "lodash";
import React from "react";
import {Pane, Strong} from 'evergreen-ui';

const orderPrintingDataView = (params) => {
  const reference = _.get(params, 'data.eventPayload.reference', '');
  const supplier = _.get(params, 'data.eventPayload.supplier', '');
  return (
    <Pane display="flex" flexWrap="wrap">
      <Pane flexBasis="max-content" marginRight={20}><Strong>Reference: </Strong>{reference}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Supplier: </Strong>{supplier}</Pane>
    </Pane>
  )
}

export default orderPrintingDataView;