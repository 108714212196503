import {Pane, Strong} from "evergreen-ui";
import React from "react";
import _ from "lodash";
import moment from "moment";

const StoreOrderArchivedDataView = (params) => {
  const orderId = _.get(params, 'data.eventPayload.data.id', '');
  const createdAt = _.get(params, 'data.eventPayload.created_at',null);
  const createdAtInStr = !!createdAt ? moment(createdAt * 1000).format('YYYY-MM-DD HH:mm:ss') : '';

  return (
    <Pane display="flex" flexWrap="wrap">
      <Pane flexBasis="max-content" marginRight={20}><Strong>BC Order ID: </Strong>{orderId}</Pane>
      <Pane flexBasis="max-content" marginRight={20}><Strong>Created At: </Strong>{createdAtInStr}</Pane>
    </Pane>
  )
};

export default StoreOrderArchivedDataView;
