import {
  Pane,
  majorScale,
  minorScale,
  Avatar,
  Popover,
  ChevronDownIcon,
  defaultTheme,
  Strong,
  Menu,
  Position,
  Spinner,
  Text,
  LogOutIcon,
  UserIcon,
  CogIcon
} from 'evergreen-ui';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from 'react-router-dom';

import {useEffect} from 'react';

import logo from './logo.23444f77.svg';
import logoRight from './logo-right.webp';
import useAuthentication from './useAuthentication';
import {Redirect} from 'react-router';
import theme from './theme';
import Manufacturing from './Manufacturing';
import Fulfilment from './Fulfilment';
import NotificationConfig from './NotificationConfig';
import Admin from './admin/Admin'

// Evergreen ThemeProvider doesn't really work yet https://github.com/segmentio/evergreen/issues/542
const soleilFontFamily = 'Soleil,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;';
defaultTheme.fontFamilies.display = soleilFontFamily;
defaultTheme.fontFamilies.ui = soleilFontFamily;
defaultTheme.components.Heading.sizes["800"].fontFamily = soleilFontFamily;
defaultTheme.components.Heading.sizes["800"].fontWeight = 800;
defaultTheme.components.Heading.sizes["800"].color = theme.colors.noissuePurple;

const routes = [{
  displayName: 'Manufacturing',
  path: '/manufacturing',
  requiredRole: 'supplier',
  component: Manufacturing
}, {
  displayName: 'Fulfilment',
  path: '/fulfilment',
  requiredRole: 'shipper',
  component: Fulfilment
}, {
  displayName: 'Admin',
  path: '/admin',
  requiredRole: 'admin',
  component: Admin
}];

function App() {
  const {
    isAuthenticated,
    isLoading,
    user,
    error,
    signIn,
    signOut,
    isPermitted
  } = useAuthentication();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isLoading && !isAuthenticated && !error) {
      signIn();
    }
  }, [isAuthenticated, isLoading, error, signIn]);

  return (
    user === null ? <Spinner marginX="auto" marginY="auto" /> :
      <Router>
        <Pane>
          <Pane is="header"
                elevation={2}
                paddingLeft={majorScale(12)}
                paddingRight={majorScale(12)}
                height={94}
                display="flex"
                alignItems="center">
            <Pane display="flex" flexDirection="row" alignItems="center" flexGrow={1}>
              <Pane display="flex" flexBasis="120px" marginBottom={minorScale(1)} marginRight={majorScale(8)}>
                <img src={logo} alt="logo"/>
              </Pane>
              {routes.filter(r => isPermitted(r.requiredRole)).map((r, i) => (
                <Strong
                  key={i}
                  color={theme.colors.noissuePurple}
                  marginTop={majorScale(1)}
                  marginRight={majorScale(4)}
                  display="flex"
                  size={400}
                  textTransform="uppercase">
                  <NavLink to={r.path} activeStyle={{color: theme.colors.noissuePink}}>{r.displayName}</NavLink>
                </Strong>
              ))}
              <Pane flexGrow={1}/>
              <Pane display="flex" alignContent="center">
                <Popover
                  position={Position.BOTTOM_LEFT}
                  content={
                    <Menu>
                      <Menu.Group>
                        <Menu.Item icon={UserIcon} disabled cursor="default">
                          <Text>{user.signInUserSession.idToken.payload.email}</Text>
                        </Menu.Item>
                        {isPermitted('supplier') || isPermitted('shipper') ?
                          <Menu.Item icon={CogIcon}>
                            <NavLink to='/notification-config'
                                     activeStyle={{color: theme.colors.noissuePink}}>
                              Notifications
                            </NavLink>
                          </Menu.Item>
                          : ''
                        }
                      </Menu.Group>
                      <Menu.Divider/>
                      <Menu.Group>
                        <Menu.Item icon={LogOutIcon} onSelect={signOut}>Logout</Menu.Item>
                      </Menu.Group>
                    </Menu>
                  }>
                  <Pane display="flex" marginTop={majorScale(1)} cursor="pointer">
                    <Avatar src={logoRight} size={38}/>
                    <Strong
                      size={400}
                      textTransform="uppercase"
                      marginLeft={majorScale(2)}
                      color={theme.colors.noissuePink}
                      alignSelf="center">My Account
                    </Strong>
                    <ChevronDownIcon
                      color={theme.colors.noissuePink}
                      alignSelf="center"
                      marginLeft={majorScale(1)}
                      marginBottom={minorScale(1)}/>
                  </Pane>
                </Popover>
              </Pane>
            </Pane>
          </Pane>
          <Pane>
            <Switch>
              <Route exact path="/">
                <Redirect to={routes.filter(i => isPermitted(i.requiredRole))[0].path} />
              </Route>
              {routes.filter(r => isPermitted(r.requiredRole)).map((route, i) => (
                <Route key={i} path={route.path}>
                  <route.component />
                </Route>
              ))}
              {isPermitted('supplier') || isPermitted('shipper') ?
                <Route path='/notification-config'>
                  <NotificationConfig/>
                </Route>
                : ''
              }
            </Switch>
          </Pane>
        </Pane>
      </Router>
    );
}
export default App;